import { css } from 'styled-components';

export const fontObject = {
  BloomSpeakOT: {
    Regular: 'BloomSpeakOT Regular',
    Bold: 'BloomSpeakOT Bold',
    Medium: 'BloomSpeakOT Heavy',
    Semibold: 'BloomSpeakOT Heavy',
    Light: 'BloomSpeakOT Light',
  },
};

const text = css`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black_text};
`;

export const FontsStyleWrapper = css`
  .light-text {
    ${text}
    font-family: ${({ theme }) => (theme.font ? fontObject[theme.font]?.['Light'] : 'Lato Light')};
  }

  .regular-text {
    ${text}
    font-family: ${({ theme }) => (theme.font ? fontObject[theme.font]?.['Regular'] : 'Lato Regular')};
  }

  .medium-text {
    ${text}
    font-family: ${({ theme }) => (theme.font ? fontObject[theme.font]?.['Medium'] : 'Lato Medium')};
  }

  .bold-text {
    ${text}
    font-family: ${({ theme }) => (theme.font ? fontObject[theme.font]?.['Bold'] : 'Lato Bold')};
  }

  .semibold-text {
    ${text}
    font-family: ${({ theme }) => (theme.font ? fontObject[theme.font]?.['Semibold'] : 'Lato Semibold')};
  }

  .roboto-light-text {
    ${text}
    font-family: ${({ theme }) => (theme.font ? fontObject[theme.font]?.['Light'] : 'Roboto Light')};
  }

  .roboto-regular-text {
    ${text}
    font-family: ${({ theme }) => (theme.font ? fontObject[theme.font]?.['Regular'] : 'Roboto Regular')};
  }

  .roboto-medium-text {
    ${text}
    font-family: ${({ theme }) => (theme.font ? fontObject[theme.font]?.['Medium'] : 'Roboto Medium')};
  }

  .roboto-bold-text {
    ${text}
    font-family: ${({ theme }) => (theme.font ? fontObject[theme.font]?.['Bold'] : 'Roboto Bold')};
  }

  .antipasto-regular-text {
    ${text}
    font-family: ${({ theme }) => (theme.font ? fontObject[theme.font]?.['Regular'] : 'Antipasto Regular')};
  }

  .antipasto-medium-text {
    ${text}
    font-family: ${({ theme }) => (theme.font ? fontObject[theme.font]?.['Medium'] : 'Antipasto Medium')};
  }

  .antipasto-semibold-text {
    ${text}
    font-family: ${({ theme }) => (theme.font ? fontObject[theme.font]?.['Semibold'] : 'Antipasto Semibold')};
  }

  .main-grey-text {
    color: ${({ theme }) => theme.colors.main_grey};
  }

  .blue-text {
    color: ${({ theme }) => theme.colors.blue};
  }

  .pink-text {
    color: ${({ theme }) => theme.colors.pink};
  }

  .field-accent-color-text {
    color: ${({ theme }) => theme.colors.field_accent_color_text};
  }
`;
