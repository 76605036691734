import styled from 'styled-components';

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-family: Roboto Bold;
  font-style: normal;
  width: ${props => props.width};
  cursor: pointer;
  padding: 8px;
  font-weight: 600;
  color: ${props => props.color};
  background: ${props => props.bgColor};
  height: ${props => props.height};
  font-size: ${props => props.fontSize};
  border: ${props => props.borderWidth} solid ${props => props.borderColor};
  border-radius: ${props => props.borderRadius};

  * {
    cursor: pointer;
  }

  svg {
    margin-right: 8px;
  }

  label {
    color: ${props => props.color};
    font-size: ${props => props.fontSize};
  }

  &.primary {
    background: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    width: 100%;

    label {
      color: ${({ theme }) => theme.colors.white};
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed !important;

      * {
        cursor: not-allowed !important;
      }
    }
  }

  &.secondary {
    background: transparent;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    width: 100%;

    label {
      color: ${({ theme }) => theme.colors.primary};
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed !important;

      * {
        cursor: not-allowed !important;
      }
    }
  }

  &.additional {
    background: ${({ theme }) => theme.colors.additional};
    border: 1px solid ${({ theme }) => theme.colors.additional};

    label {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  &.custom-button-primary {
    background: ${({ theme }) => theme.colors.button_color};
    border: 1px solid ${({ theme }) => theme.colors.button_color};
    width: 100%;

    label {
      color: ${({ theme }) => theme.colors.white};
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed !important;

      * {
        cursor: not-allowed !important;
      }
    }
  }

  &.custom-button-secondary {
    background: transparent;
    border: 1px solid ${({ theme }) => theme.colors.button_color};
    width: 100%;
    transition: all 0.2s ease-in-out;

    label {
      color: ${({ theme }) => theme.colors.button_color};
    }

    &.hover-button {
      &:hover {
        background: ${({ theme }) => theme.colors.button_color};
        border: 1px solid ${({ theme }) => theme.colors.button_color};

        label {
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed !important;

      * {
        cursor: not-allowed !important;
      }
    }
  }

  &.specified-width {
    width: ${({ width }) => width};
  }
`;

export default ButtonWrapper;
