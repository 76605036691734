import React from 'react';

import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';

import Main from '../pages/main/main';
import Toast from '../popup/toast/toast';
import history from './history';

export const Router = () => {
  return (
    <HashRouter history={history}>
      <Routes>
        <Route element={<Main />} path="/form/:form_id" />
        <Route path="/*" element={<Navigate replace to={'/form/10083006-3aa4-11ee-9881-42010a9a0008'} />} />
      </Routes>
      <Toast />
    </HashRouter>
  );
};
