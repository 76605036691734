import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { OrganisationContext } from '../../../context/organisationContext';
import { getAddressDetails, getAddressList } from '../../../store/features/addressSlice';
import { addToast } from '../../../store/features/toastSlice';
import AsyncDropdown from '../asyncDropdown';

const Address = ({
  placeholder,
  selectedValue,
  onAddressUpdate = () => {},
  name = '',
  is_required = false,
  error,
  error_text = '',
}) => {
  const dispatch = useDispatch();

  const { api_key } = useContext(OrganisationContext);

  const [inputValue, setInputValue] = useState(null);

  const loadOptions = inputValue => {
    return dispatch(getAddressList({ api_key, params: { search: inputValue } }))
      .then(data => {
        return data.map(d => ({ ...d, label: d.address, value: d.id }));
      })
      .catch(error => {
        const errorText = error?.response?.data?.errorDescription || 'Error while fetching address list';
        dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
        return [];
      });
  };

  const onAddressSelect = address => {
    dispatch(getAddressDetails({ api_key, id: address.id }))
      .then(data => {
        onAddressUpdate(data);
      })
      .catch(e => {
        return '';
      });
  };

  const onInputChange = (text, { action }) => action === 'input-change' && setInputValue(text);

  return (
    <AddressWrapper className="flex items-start w-full">
      <AsyncDropdown
        placeholder={placeholder}
        loadOptions={loadOptions}
        onInputChange={onInputChange}
        inputValue={inputValue}
        onChange={onAddressSelect}
        selectedValue={selectedValue ? { label: selectedValue } : null}
        name={name}
        is_required={is_required}
        error={error}
        error_text={error_text}
      />
    </AddressWrapper>
  );
};

const AddressWrapper = styled('div')``;

export default Address;
