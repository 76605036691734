import React from 'react';
import styled from 'styled-components';

const InputElement = ({
  name,
  is_required = false,
  value,
  defaultValue,
  onChange = () => {},
  placeholder,
  className = '',
  error,
  disabled = false,
  error_text = '',
  autofill = 'off',
}) => {
  return (
    <InputElementWrapper className={className}>
      <div className="mb-1">
        <span className="regular-text font-12 main-grey-text">{name}</span>
        {is_required && <span className="required-star ml-1">*</span>}
      </div>
      <input
        autoComplete={autofill}
        className={`regular-text font-16 px-4 w-full input-element ${error && 'input-error'}`}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        type="text"
        placeholder={placeholder}
        onChange={({ target: { value } }) => onChange(value)}
      />
      {error && error_text && (
        <div>
          <span className="roboto-regular-text font-12 pink-text">{error_text}</span>
        </div>
      )}
    </InputElementWrapper>
  );
};

const InputElementWrapper = styled('div')`
  .input-element {
    height: 40px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 6px;
    outline: none;

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.blue_hover};
    }

    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.field_accent_color};
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.main_grey};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .input-error {
    border-color: ${({ theme }) => theme.colors.pink};

    &:hover {
      border-color: ${({ theme }) => theme.colors.pink};
    }

    &:focus {
      border-color: ${({ theme }) => theme.colors.pink};
    }
  }
`;

export default InputElement;
