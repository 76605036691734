const themeHelper = {
  down: breakpoint => `@media screen and (max-width: ${breakpoint})`,
  up: breakpoint => `@media screen and (min-width: ${breakpoint})`,
  breakpoints: {
    lg: '1024px',
    xl: '1920px',
  },
};

export const light = {
  ...themeHelper,
  colors: {
    primary: '#FF69B4',
    additional: '#E9FAFF',
    white: '#FFFFFF',
    sidebarBackground: '#023544',
    shadowColor: 'rgba(5, 49, 73, 0.08)',
    modalBackground: 'rgba(22, 25, 44, 0.8)',
    disabled: '#CFE3E9',
    border: '#DEE4F0',

    background: 'rgba(222, 228, 240, 0.3)',

    steps_background: '#F7F8FC',

    dropdown_option_hover: 'rgba(75, 105, 178, 0.05)',

    black_text: '#1D1816',

    main_grey: '#9A9A9A',

    light_blue: '#9CDBEE',

    light_purple: '#AABAB7',

    main_orange: '#FD9F2D',

    pink: 'rgba(255, 105, 180, 1)',
    pink_light: 'rgba(255, 105, 180, 0.1)',

    light_pink: '#FEE0EA',

    blue: '#4B69B2',
    blue_hover: 'rgba(7, 105, 178, 0.5)',
    field_accent_color: '#4B69B2',
    field_accent_color_text: 'rgba(255, 105, 180, 1)',
    checkbox_checked: 'rgba(255, 105, 180, 1)',
    button_color: '#FF69B4',
  },
};

export const dark = {
  ...themeHelper,
  colors: {
    primary: '#FF69B4',
    additional: '#E9FAFF',
    white: '#FFFFFF',
    sidebarBackground: '#023544',
    shadowColor: 'rgba(5, 49, 73, 0.08)',
    modalBackground: 'rgba(22, 25, 44, 0.8)',
    disabled: '#CFE3E9',
    border: '#DEE4F0',

    background: 'rgba(222, 228, 240, 0.3)',

    steps_background: '#F7F8FC',

    dropdown_option_hover: 'rgba(75, 105, 178, 0.05)',

    black_text: '#1D1816',

    main_grey: '#9A9A9A',

    light_blue: '#9CDBEE',

    light_purple: '#AABAB7',

    main_orange: '#FD9F2D',

    pink: '#FF9CCE',
    pink_light: 'rgba(255, 105, 180, 0.1)',

    light_pink: '#FEE0EA',

    blue: '#4B69B2',
    field_accent_color: '#4B69B2',
    field_accent_color_text: 'rgba(255, 105, 180, 1)',
    checkbox_checked: 'rgba(255, 105, 180, 1)',
    button_color: '#FF69B4',
  },
};
