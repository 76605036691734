import React, { useState } from 'react';
import HttpsRedirect from 'react-https-redirect';
import { useTranslation } from 'react-i18next';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import ReloadIcon from './assets/images/reload.svg';
import Button from './components/elements/button/button';
import { LanguageProvider } from './context/LanguageContext';
import { ThemePreferenceContextProvider, initialThemeState } from './context/ThemePreferenceContext';
import { getLanguage } from './helpers/i18n';
import { Router } from './routes/router';
import { CommonStyleWrapper } from './styles/Common.styled';
import GlobalStyles from './styles/global-styles';
import { dark, light } from './styles/theme/index';
import './translations/i18n';

const themesMap = {
  light,
  dark,
};

const languageOptions = [
  { value: 'ENGLISH', label: 'English', code: 'en' },
  { value: 'WELSH', label: 'Welsh', code: 'cy' },
];

const App = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.reload);
  const { i18n } = useTranslation();

  const languageOption = languageOptions.find(option => option.code === getLanguage());

  const [language, setLanguage] = useState(languageOption);
  const [theme, setTheme] = useState(initialThemeState.theme);
  const [themeMapState, setThemeMapState] = useState(themesMap);

  const onLanguageChangeInTranslation = lang => {
    i18n.changeLanguage(lang);
  };

  const onLanguageChange = langCode => {
    const langOption = languageOptions.find(option => option.code === langCode);
    onLanguageChangeInTranslation(langCode);
    setLanguage(langOption);
  };

  return (
    <LanguageProvider value={{ onLanguageChange, language }}>
      <ThemePreferenceContextProvider
        value={{ theme, setTheme, themeMapState, setThemeMapState, initialThemeMapState: themesMap }}>
        <ThemeProvider theme={themeMapState[theme]}>
          <SkeletonTheme baseColor="#ffffff" highlightColor="#c3c3c396">
            <GlobalStyles />
            <CommonStyleWrapper />
            <div className={`App theme-${theme}`}>
              <HttpsRedirect>
                <Router />
                {state.updated && (
                  <div className="fixed new-version-popup flex justify-center pxy-6">
                    <div className="flex flex-column items-center card mt-20 px-12 py-8 refresh-view">
                      <label className="bold-text font-24">New version available!</label>
                      <label className="regular-text lighter-text text-center mt-2">
                        Please refresh the page to get access to new platform updates
                      </label>
                      <img className="mt-10 flex-1" src={ReloadIcon} />
                      <Button
                        label="Refresh"
                        className="primary mt-10"
                        borderRadius="16px"
                        width="100%"
                        size="large"
                        onClick={() => {
                          state.serviceWorkerRegistration.waiting.postMessage({ type: 'SKIP_WAITING' });
                          dispatch(init());
                          window.location.reload();
                        }}
                      />
                    </div>
                  </div>
                )}
              </HttpsRedirect>
            </div>
          </SkeletonTheme>
        </ThemeProvider>
      </ThemePreferenceContextProvider>
    </LanguageProvider>
  );
};

export default App;
