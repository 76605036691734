import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import { ReactComponent as DirectDebit } from '../../assets/images/direct-debit.svg';
import { specialCharRegEx } from '../../constant/optionData';
import LanguageContext from '../../context/LanguageContext';
import { OrganisationContext } from '../../context/organisationContext';
import { getDates, uppercase } from '../../helpers/utils';
import Box from '../elements/box';
import Button from '../elements/button/button';
import Checkbox from '../elements/checkbox';
import InputElement from '../elements/input';

const dateFormatByLanguage = {
  en: { day: 'Do', month: 'MMMM', year: 'YYYY' },
  cy: { day: 'D', month: 'MMMM', year: 'YYYY' },
};

const DebitRegistration = ({
  debitRegistration,
  setDebitRegistration,
  onBack,
  onSubmit,
  debitRegistrationError = {},
  isSubmittingForm = false,
}) => {
  const { t } = useTranslation();

  const { getComponentByLexicon } = useContext(OrganisationContext);
  const { language } = useContext(LanguageContext);
  const { code: langCode } = language || {};

  const { day, month, year } = dateFormatByLanguage[langCode] || { day: 'Do', month: 'MMMM', year: 'YYYY' };

  const vendorServiceUserNumber = getComponentByLexicon('vendor.service_user_number');
  const vendorDirectDebitLabel = getComponentByLexicon('vendor.direct_debit_label');
  const serviceUserNumber = vendorServiceUserNumber ? vendorServiceUserNumber?.name?.split('') || [] : [];

  const [paymentDate, _setPaymentDate] = useState(getDates());

  useEffect(() => {
    if (!debitRegistration.date) {
      setDebitRegistration({ ...debitRegistration, date: paymentDate?.first?.utc().unix() });
    }
  }, [paymentDate]);

  return (
    <DebitRegistrationWrapper>
      <Box className="contact-wrapper" title={t('SECURE_DIRECT_DEBIT_REGISTRATION')}>
        <div className="flex-column w-full mt-8">
          <label className="regular-text font-12 main-grey-text mb-1">{t('SELECT_DATE_OF_FIRST_PAYMENT')}</label>
          <div className="border pxy-4 white-background radius-3 date-details col-gap-10 mb-8">
            <Checkbox
              name={t('PAYMENT_DATE_FORMAT', {
                day: paymentDate?.first?.format(day),
                month: t(uppercase(paymentDate?.first?.format(month))),
                year: paymentDate?.first?.format(year),
              })}
              is_rounded
              checked={debitRegistration.date === paymentDate?.first?.unix()}
              onChange={() => setDebitRegistration({ ...debitRegistration, date: paymentDate?.first?.utc().unix() })}
              error={debitRegistrationError.date && !debitRegistration.date}
            />
            <Checkbox
              name={t('PAYMENT_DATE_FORMAT', {
                day: paymentDate?.last?.format(day),
                month: t(uppercase(paymentDate?.last?.format(month))),
                year: paymentDate?.last?.format(year),
              })}
              is_rounded
              checked={debitRegistration.date === paymentDate?.last?.unix()}
              onChange={() => setDebitRegistration({ ...debitRegistration, date: paymentDate?.last?.utc().unix() })}
              error={debitRegistrationError.date && !debitRegistration.date}
            />
          </div>
          <InputElement
            is_required
            className="mb-6"
            name={t('ACCOUNT_HOLDER_NAME')}
            placeholder={t('ACCOUNT_HOLDER_NAME')}
            value={debitRegistration.accountHolderName}
            onChange={value =>
              setDebitRegistration({ ...debitRegistration, accountHolderName: value.replace(specialCharRegEx, '') })
            }
            error={debitRegistrationError.accountHolderName && !debitRegistration.accountHolderName}
          />
          <div className="date-details col-gap-10 mb-8">
            <div className="flex-column">
              <div className="flex">
                <label className="regular-text font-12 main-grey-text mb-2">{t('SORT_CODE')}</label>
                <span className="required-star ml-1">*</span>
              </div>
              <MaskedInput
                className={`input ${debitRegistrationError.sortCode && !debitRegistration.sortCode && 'error-border'}`}
                mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                guide={false}
                placeholder={t('SORT_CODE_PLACEHOLDER')}
                value={debitRegistration.sortCode}
                onChange={({ target: { value } }) => setDebitRegistration({ ...debitRegistration, sortCode: value })}
              />
            </div>
            <div className="flex-column">
              <div className="flex">
                <label className="regular-text font-12 main-grey-text mb-2">{t('ACCOUNT_NUMBER')}</label>
                <span className="required-star ml-1">*</span>
              </div>
              <MaskedInput
                className={`input ${
                  debitRegistrationError.accountNumber && !debitRegistration.accountNumber && 'error-border'
                }`}
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                guide={false}
                placeholder={t('ACCOUNT_NUMBER_PLACEHOLDER')}
                value={debitRegistration.accountNumber}
                onChange={({ target: { value } }) =>
                  setDebitRegistration({ ...debitRegistration, accountNumber: value })
                }
              />
            </div>
          </div>
          <Checkbox
            is_required
            className="mb-4"
            name={t('AUTHORIZED_TO_SET_DIRECT_DEBIT_ON_ACCOUNT')}
            checked={debitRegistration.authorizedDebitCheck}
            onChange={value => setDebitRegistration({ ...debitRegistration, authorizedDebitCheck: value })}
            error={debitRegistrationError.authorizedDebitCheck && !debitRegistration.authorizedDebitCheck}
          />
          <Checkbox
            is_required
            className="mb-4"
            name={t('YEARS_18_OLD_OR_OVER')}
            checked={debitRegistration.validAgeCheck}
            onChange={value => setDebitRegistration({ ...debitRegistration, validAgeCheck: value })}
            error={debitRegistrationError.validAgeCheck && !debitRegistration.validAgeCheck}
          />
          <Checkbox
            is_required
            className="mb-8"
            name={t('RESIDENT_OF_GREAT_BRITAIN')}
            checked={debitRegistration.residentOfBritain}
            onChange={value => setDebitRegistration({ ...debitRegistration, residentOfBritain: value })}
            error={debitRegistrationError.residentOfBritain && !debitRegistration.residentOfBritain}
          />
          <label className="roboto-medium-text font-16 mb-4">{t('INSTRUCTION_TO_BANK_OR_SOCIETY')}</label>
          <label className="roboto-regular-text mb-6">
            {t('PAY_ZENLOTTERY', {
              direct_debit_label: vendorDirectDebitLabel?.name,
            })}
          </label>
          <hr className="my-8" />
          <div className="flex mb-6">
            <DirectDebit />
            <div className="flex-column flex-1 ml-4">
              <label className="roboto-medium-text font-16 mb-3">{t('SERVICE_USER_NUMBER')}</label>
              <div className="flex service-user-number">
                {serviceUserNumber.map((number, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-center radius-2 border card mr-2 mb-2 service-number-wrapper">
                    <span className="regular-text font-16">{number}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <label className="roboto-medium-text font-16 mb-4">{t('DIRECT_DEBIT_GURANTEE')}:</label>
          <ul className="unordered-list">
            <li className="roboto-regular-text mb-6">
              {t('DIRECT_DEBIT_GURANTEE_PARAGRAPH_1', {
                direct_debit_label: vendorDirectDebitLabel?.name,
              })}
            </li>
            <li className="roboto-regular-text mb-6">
              {t('DIRECT_DEBIT_GURANTEE_PARAGRAPH_2', {
                direct_debit_label: vendorDirectDebitLabel?.name,
              })}
            </li>
            <li className="roboto-regular-text mb-6">
              {t('DIRECT_DEBIT_GURANTEE_PARAGRAPH_3', {
                direct_debit_label: vendorDirectDebitLabel?.name,
              })}
            </li>
            <li className="roboto-regular-text mb-6">
              {t('DIRECT_DEBIT_GURANTEE_PARAGRAPH_4', {
                direct_debit_label: vendorDirectDebitLabel?.name,
              })}
            </li>
          </ul>
          <div className="flex col-gap-10 mt-10 action-wrapper">
            <Button
              disabled={isSubmittingForm}
              label={t('BACK')}
              className="secondary custom-button-secondary"
              borderRadius="0px"
              onClick={() => onBack()}
            />
            <Button
              disabled={isSubmittingForm}
              label={t('REVIEW')}
              className="primary custom-button-primary"
              borderRadius="0px"
              onClick={() => onSubmit()}
            />
          </div>
        </div>
      </Box>
    </DebitRegistrationWrapper>
  );
};

export const DebitRegistrationWrapper = styled.div`
  .date-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .service-number-wrapper {
    height: 40px;
    width: 40px;
  }

  .contact-wrapper {
    padding-bottom: 48px;
  }

  .otp-input {
    width: 40px !important;
    height: 40px;
    padding: 0;
    text-align: 'center';
  }

  .unordered-list {
    padding-left: 12px;
  }

  @media (max-width: 961px) {
    .date-details {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 24px;
    }
    .service-user-number {
      flex-wrap: wrap;
    }
    .action-wrapper {
      flex-direction: column;
      row-gap: 24px;
    }
  }
`;

export default DebitRegistration;
