import React from 'react';
import Highlighter from 'react-highlight-words';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as ArrowUpDownIcon } from '../../../assets/images/arrow-up-down.svg';
import { fontObject } from '../../../styles/fonts.styled';

const Option = props => {
  const { innerProps, data, isSelected, selectProps } = props;
  const { inputValue } = selectProps;
  return (
    <div
      className={`flex items-center w-full pxy-2 radius-1 cursor option-wrapper ${isSelected ? 'selected' : ''}`}
      {...innerProps}>
      <span className={`flex-1 regular-text font-16 option-text ${isSelected && 'semibold-text'}`}>
        <Highlighter
          searchWords={[inputValue]}
          autoEscape={true}
          textToHighlight={data.label}
          highlightTag={({ children, _highlightIndex }) => <strong className="highlighted-text">{children}</strong>}
        />
      </span>
    </div>
  );
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowUpDownIcon />
    </components.DropdownIndicator>
  );
};

const AsyncDropdown = ({
  placeholder = '',
  onChange = () => {},
  selectedValue = null,
  isMulti = false,
  loadOptions = () => {},
  onInputChange = () => {},
  inputValue = null,
  className = '',
  name = '',
  is_required = false,
  error = false,
  error_text = '',
}) => {
  const theme = useTheme();

  const dropdownStyle = {
    control: (baseStyles, { selectProps: { menuIsOpen } }) => ({
      ...baseStyles,
      borderColor: error ? theme.colors.pink : menuIsOpen ? theme.colors.field_accent_color : theme.colors.border,
      borderRadius: '6px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minHeight: '40px',
      cursor: 'pointer',
      boxShadow: 'none',

      '&:hover': {
        borderColor: error ? theme.colors.pink : menuIsOpen ? theme.colors.field_accent_color : theme.colors.blue_hover,
      },
    }),
    placeholder: baseStyles => ({
      ...baseStyles,
      fontFamily: theme.font ? fontObject[theme.font]?.['Regular'] : 'Lato Regular',
      color: theme.colors.main_grey,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: baseStyles => ({
      ...baseStyles,
      color: theme.colors.black_text,
    }),
    valueContainer: baseStyles => ({
      ...baseStyles,
      fontFamily: theme.font ? fontObject[theme.font]?.['Regular'] : 'Lato Regular',
      padding: '4px 16px',
    }),
    menu: baseStyles => ({
      ...baseStyles,
      borderRadius: '6px',
      padding: '8px',
      zIndex: 11,
    }),
    menuList: baseStyles => ({
      ...baseStyles,
      padding: '0px',
    }),
    clearIndicator: () => ({
      display: 'none',
    }),
    noOptionsMessage: baseStyles => ({
      ...baseStyles,
      fontFamily: theme.font ? fontObject[theme.font]?.['Regular'] : 'Lato Regular',
    }),
  };

  return (
    <AsyncDropdownWrapper className={`w-full ${className}`}>
      <div className="mb-1">
        <span className="regular-text main-grey-text font-12">{name}</span>
        {is_required && <span className="required-star ml-1">*</span>}
      </div>
      <AsyncSelect
        loadOptions={loadOptions}
        placeholder={placeholder}
        onChange={option => {
          onInputChange('', { action: 'input-change' });
          onChange(option);
        }}
        onInputChange={onInputChange}
        inputValue={inputValue}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={false}
        value={selectedValue}
        styles={dropdownStyle}
        menuPlacement="auto"
        components={{
          Option,
          DropdownIndicator,
        }}
      />
      {error && error_text && (
        <div>
          <span className="roboto-regular-text font-12 pink-text">{error_text}</span>
        </div>
      )}
    </AsyncDropdownWrapper>
  );
};

export const AsyncDropdownWrapper = styled.div`
  .option-wrapper {
    &:hover {
      background-color: ${({ theme }) => theme.colors.dropdown_option_hover};
      .option-text {
        color: ${({ theme }) => theme.colors.field_accent_color};
      }
    }
  }

  .selected {
    background-color: ${({ theme }) => theme.colors.dropdown_option_hover};
    .option-text {
      color: ${({ theme }) => theme.colors.field_accent_color};
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.dropdown_option_hover};
    }
  }
`;

export default AsyncDropdown;
