import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Address from '../elements/address';
import Box from '../elements/box';
import InputElement from '../elements/input';

const formatted_address_format = ['line1', 'line2', 'line3', 'city', 'state', 'postcode'];

const AddressInfo = ({ address, setAddress, addressError = {} }) => {
  const { t } = useTranslation();

  const onAddressUpdate = address => {
    const formatted_address = formatted_address_format
      .map(a => address[a])
      .filter(value => Boolean(value))
      .join(', ');
    setAddress({ ...address, formatted_address });
  };

  const onChangeAddress = (key, value) => {
    const updatedAddress = { ...address, [key]: value };
    const formatted_address = formatted_address_format
      .map(a => updatedAddress[a])
      .filter(value => Boolean(value))
      .join(', ');
    setAddress({ ...updatedAddress, formatted_address });
  };

  return (
    <AddressInfoWrapper>
      <Box isSmallBox={true} title={t('YOUR_ADDRESS_DETAILS')}>
        <div className="flex w-full mt-8 col-gap-6">
          <Address
            is_required
            name={t('ADDRESS')}
            placeholder={t('ENTER_YOUR_ADDRESS')}
            onAddressUpdate={onAddressUpdate}
            selectedValue={address.formatted_address}
            error={addressError.formatted_address && !address.formatted_address}
            error_text={t('PLEASE_ENTER_ADDRESS')}
          />
        </div>
        <div className="address-details w-full mt-6 col-gap-10 row-gap-6">
          <InputElement
            is_required
            className="w-full"
            name={t('ADDRESS_LINE_1')}
            placeholder={t('ENTER')}
            value={address.line1}
            onChange={value => onChangeAddress('line1', value)}
            error={addressError.line1 && !address.line1}
            autofill={'address-line1'}
          />
          <InputElement
            className="w-full"
            name={t('ADDRESS_LINE_2')}
            placeholder={t('ENTER')}
            value={address.line2}
            onChange={value => onChangeAddress('line2', value)}
            error={addressError.line2 && !address.line2}
            autofill={'address-line2'}
          />
          <InputElement
            className="w-full"
            name={t('ADDRESS_LINE_3')}
            placeholder={t('ENTER')}
            value={address.line3}
            onChange={value => onChangeAddress('line3', value)}
            error={addressError.line3 && !address.line3}
            autofill={'address-line3'}
          />
          <InputElement
            is_required
            className="w-full"
            name={t('TOWN_CITY')}
            placeholder={t('ENTER')}
            value={address.city}
            onChange={value => onChangeAddress('city', value)}
            error={addressError.city && !address.city}
            autofill={'address-level2'}
          />
          <InputElement
            className="w-full"
            name={t('COUNTY')}
            placeholder={t('ENTER')}
            value={address.state}
            onChange={value => onChangeAddress('state', value)}
            error={addressError.state && !address.state}
            autofill={'address-level1'}
          />
          <InputElement
            is_required
            className="w-full"
            name={t('POSTCODE')}
            placeholder={t('ENTER')}
            value={address.postcode}
            onChange={value => onChangeAddress('postcode', value)}
            error={addressError.postcode && !address.postcode}
            autofill={'postal-code'}
          />
        </div>
      </Box>
    </AddressInfoWrapper>
  );
};

export const AddressInfoWrapper = styled.div`
  .address-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 961px) {
    .address-details {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export default AddressInfo;
