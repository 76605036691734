import React from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';

const PhoneInput = ({
  name,
  is_required,
  onChange = () => {},
  value = '',
  error,
  error_text = '',
  is_mobile = false,
  autofill = 'off',
}) => {
  const mask = is_mobile
    ? [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]
    : [/\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

  return (
    <PhoneInputWrapper className="w-full" is_mobile={is_mobile}>
      <div className="mb-1">
        <span className="regular-text font-12 main-grey-text">{name}</span>
        {is_required && <span className="required-star ml-1">*</span>}
      </div>
      <div className="flex flex-1 relative">
        {is_mobile && (
          <div className="mobile-placeholder border-right">
            <span className="regular-text font-16">+44</span>
          </div>
        )}
        <MaskedInput
          mask={mask}
          value={value || null}
          className={`input w-full phone-input ${error && 'phone-input-error'}`}
          placeholder={is_mobile ? '1234 567 890' : '01903 456372'}
          guide={false}
          onChange={({ target: { value } }) => onChange(value)}
          autoComplete={autofill}
        />
      </div>
      {error && error_text && (
        <div>
          <span className="roboto-regular-text font-12 pink-text">{error_text}</span>
        </div>
      )}
    </PhoneInputWrapper>
  );
};

const PhoneInputWrapper = styled('div')`
  .mobile-placeholder {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: 6px 0 0 6px;
  }

  .input {
    padding-left: ${({ is_mobile }) => (is_mobile ? 70 : 16)}px;
  }

  .phone-input-error {
    border-color: ${({ theme }) => theme.colors.pink};

    &:hover {
      border-color: ${({ theme }) => theme.colors.pink};
    }

    &:focus {
      border-color: ${({ theme }) => theme.colors.pink};
    }
  }
`;

export default PhoneInput;
