import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { OrganisationContext } from '../../context/organisationContext';
import Box from '../elements/box';
import Checkbox from '../elements/checkbox';

const PreferanceInfo = ({ preferance, setPreferance }) => {
  const { t } = useTranslation();
  const { vendor } = useContext(OrganisationContext);

  return (
    <PreferanceInfoWrapper>
      <Box title={t('YOUR_CONTACT_PREFERENCES')}>
        <div className="flex-column w-full mt-8 col-gap-6">
          <p className="roboto-regular-text main-grey-text mt-2">{t('SELECT_HOW_TO_KEEP_IN_TOUCH')}</p>
          <div className="border radius-4 flex-column pxy-6 mt-8 white-background">
            <label className="roboto-regular-text">
              {t('VENDOR_PRODUCT_SERVICE_INFO', { vendor_name: t(vendor.name) })}
            </label>
            <div className="preference-choices mt-6">
              <Checkbox
                name={t('RECEIVE_EMAIL')}
                checked={preferance.email}
                onChange={value => setPreferance({ ...preferance, email: value })}
              />
              <Checkbox
                name={t('RECEIVE_SMS_MESSAGES')}
                checked={preferance.sms}
                onChange={value => setPreferance({ ...preferance, sms: value })}
              />
              <Checkbox
                name={t('RECEIVE_PHONE_CALL')}
                checked={preferance.phoneCall}
                onChange={value => setPreferance({ ...preferance, phoneCall: value })}
              />
            </div>
          </div>
          <div className="border radius-4 flex-column pxy-6 mt-8 white-background">
            <label className="roboto-regular-text">{t('LOTTERY_CONSET_LINES')}</label>
            <div className="preference-choices mt-6">
              <Checkbox
                name={t('RECEIVE_EMAIL')}
                checked={preferance.zenEmail}
                onChange={value => setPreferance({ ...preferance, zenEmail: value, zenNoThanks: false })}
              />
              <Checkbox
                name={t('RECEIVE_SMS_MESSAGES')}
                checked={preferance.zenSms}
                onChange={value => setPreferance({ ...preferance, zenSms: value, zenNoThanks: false })}
              />
              <Checkbox
                name={t('RECEIVE_PHONE_CALL')}
                checked={preferance.zenPost}
                onChange={value => setPreferance({ ...preferance, zenPost: value, zenNoThanks: false })}
              />
            </div>
          </div>
        </div>
      </Box>
    </PreferanceInfoWrapper>
  );
};

export const PreferanceInfoWrapper = styled.div`
  .preference-choices {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .no-preference-choice {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .choice-label {
      grid-column: span 2;
    }
  }

  @media (max-width: 961px) {
    .preference-choices {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 24px;
    }
  }
`;

export default PreferanceInfo;
