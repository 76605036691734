import React from 'react';
import styled from 'styled-components';
import { ReactComponent as EmptyImage } from '../../../assets/images/image-placeholder.svg';

const ImageElement = ({ src, alt = 'image', height, width }) => {
  return (
    <ImageWrapper className="w-full flex items-center justify-center">
      {src ? (
        <img
          className={`radius-3 image-element ${!width && 'w-full'}`}
          src={src}
          alt={alt}
          width={width}
          height={height}
          style={{ maxHeight: height ? height : '300px', aspectRatio: '2/1' }}
        />
      ) : (
        <div className="flex items-center justify-center radius-4 w-full empty-img-content mr-4">
          <EmptyImage className="empty-img" height={64} width={80} />
        </div>
      )}
    </ImageWrapper>
  );
};

const ImageWrapper = styled('div')`
  .empty-img-content {
    min-height: 124px;
    background: ${({ theme }) => theme.colors.background};

    .empty-img {
      path {
        fill: ${({ theme }) => theme.colors.divider};
        opacity: 1;
      }
    }
  }

  @media (max-width: 961px) {
    .image-element {
      max-width: 90%;
      object-fit: contain;
    }
  }
`;

export default ImageElement;
