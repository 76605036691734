import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { emailRegEx, specialCharRegEx, titleOptions } from '../../constant/optionData';
import Box from '../elements/box/index';
import Dropdown from '../elements/dropdown/index';
import InputElement from '../elements/input/index';
import PhoneInput from '../elements/phone-input/index';

const ContactInfo = ({ contact, setContact, contactError = {} }) => {
  const { t } = useTranslation();

  return (
    <ContactInfoWrapper>
      <Box className="contact-wrapper" title={t('YOUR_CONTACT_INFO')}>
        <div className="flex w-full mt-8 col-gap-6 contact-content">
          <Dropdown
            is_required
            name={t('TITLE')}
            options={titleOptions}
            placeholder={t('TITLE')}
            selectedValue={titleOptions.find(option => option.value === contact.title)}
            onChange={option => setContact({ ...contact, title: option.value })}
            error={contactError.title && !contact.title}
            error_text={t('PLEASE_SELECT_TITLE')}
            autofill="honorific-prefix"
          />
          <InputElement
            is_required
            className="w-full"
            name={t('NAME')}
            placeholder={t('NAME_PLACEHOLDER')}
            value={contact.forename}
            onChange={value =>
              setContact({
                ...contact,
                forename: value.replace(specialCharRegEx, ''),
              })
            }
            error={contactError.forename && !contact.forename}
            error_text={t('PLEASE_ENTER_FIRST_NAME')}
            autofill={'given-name'}
          />
          <InputElement
            is_required
            className="w-full"
            name={t('SURNAME')}
            placeholder={t('SURNAME_PLACEHOLDER')}
            value={contact.surname}
            onChange={value => setContact({ ...contact, surname: value.replace(specialCharRegEx, '') })}
            error={contactError.surname && !contact.surname}
            error_text={t('PLEASE_ENTER_SUR_NAME')}
            autofill={'family-name'}
          />
        </div>
        <div className="contact-details w-full mt-6 col-gap-10 row-gap-6">
          <InputElement
            is_required
            className="w-full"
            name={t('EMAIL')}
            placeholder={t('ENTER_EMAIL')}
            value={contact.email}
            onChange={value => setContact({ ...contact, email: value })}
            error={
              (contactError.email && !contact.email) || (contactError.emailInvalid && !emailRegEx.test(contact.email))
            }
            error_text={
              contactError.emailInvalid && !emailRegEx.test(contact.email)
                ? t('PLEASE_ENTER_VALID_EMAIL')
                : t('PLEASE_ENTER_EMAIL')
            }
            autofill={'email'}
          />
          <PhoneInput
            className="w-full"
            name={t('MOBILE_PHONE_NUMBER')}
            value={contact.mobile || ''}
            onChange={value => setContact({ ...contact, mobile: value })}
            error={
              (contactError.mobile && !contact.mobile && !contact.home) ||
              (contactError.mobileInvalid && contact.mobile.replaceAll('+44', '').replaceAll(' ', '').length !== 10)
            }
            error_text={t('PLEASE_ENTER_MOBILE_OR_HOME')}
            is_mobile
            autofill={'mobile'}
          />
          <PhoneInput
            className="w-full"
            name={t('HOME_PHONE_NUMBER')}
            value={contact.home || ''}
            onChange={value => setContact({ ...contact, home: value })}
            error={
              (contactError.home && !contact.home && !contact.mobile) ||
              (contactError.homeInvalid && contact.home.replaceAll('+44', '').replaceAll(' ', '').length !== 11)
            }
            error_text={t('PLEASE_ENTER_MOBILE_OR_HOME')}
            autofill={'tel-national'}
          />
        </div>
      </Box>
    </ContactInfoWrapper>
  );
};

export const ContactInfoWrapper = styled.div`
  .contact-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .contact-wrapper {
    padding-bottom: 48px;
  }
  @media (max-width: 961px) {
    .contact-content {
      flex-direction: column;
      row-gap: 24px;
    }

    .contact-details {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export default ContactInfo;
