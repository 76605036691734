import { configureStore } from '@reduxjs/toolkit';
import addressSlice from './features/addressSlice';
import formSlice from './features/formSlice';
import reloadSlice from './features/reloadSlice';
import toastSlice from './features/toastSlice';

export default configureStore({
  reducer: {
    reload: reloadSlice,
    toast: toastSlice,
    address: addressSlice,
    form: formSlice,
  },
  devTools: process.env.REACT_APP_STAGE === 'development',
});
