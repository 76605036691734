import { createGlobalStyle } from 'styled-components';
import { FontsStyleWrapper, fontObject } from './fonts.styled';

export const CommonStyleWrapper = createGlobalStyle`
${FontsStyleWrapper};

.card {
  background: ${({ theme }) => theme.colors.white};
}

* :focus-visible {
  outline: none;
}

.input {
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.black_text};
  font-family: ${({ theme }) => (theme.font ? fontObject[theme.font]?.['Regular'] : 'Lato Regular')};
  font-size: 16px;
  outline: none;
  padding-left: 16px;
  padding-right: 16px;
}

.input:hover {
  border: 1px solid ${({ theme }) => theme.colors.blue_hover};
}

.input:focus {
  border: 1px solid ${({ theme }) => theme.colors.field_accent_color};
}

.input::placeholder {
  color: ${({ theme }) => theme.colors.main_grey};
}

.textarea {
  width: 100%;
  min-height: 80px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.black_text};
  font-family: Lato Regular;
  font-size: 16px;
  outline: none;
  padding: 12px 16px;
  resize: none;
}

.textarea:focus {
  border: 1px solid ${({ theme }) => theme.colors.field_accent_color};
}

.textarea::placeholder {
  color: ${({ theme }) => theme.colors.main_grey};
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.display-block {
  display: block;
}

.word-break-all {
  word-break: break-all;
}

.word-break-break-word {
  word-break: break-word;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-end {
  align-items: flex-end;
}

.items-start {
  align-items: flex-start;
}

.line-height-20px {
  line-height: 20px;
}

hr {
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.border};
  border: none;
}

p {
  margin: 0px;
}

.flex-1 {
  flex: 1;
}

.col-gap-1 {
  column-gap: 4px;
}

.col-gap-2 {
  column-gap: 8px;
}

.col-gap-3 {
  column-gap: 12px;
}

.col-gap-4 {
  column-gap: 16px;
}

.col-gap-6 {
  column-gap: 24px;
}

.col-gap-10 {
  column-gap: 40px;
}

.row-gap-1 {
  row-gap: 4px;
}

.row-gap-2 {
  row-gap: 8px;
}

.row-gap-4 {
  row-gap: 16px;
}

.row-gap-6 {
  row-gap: 24px;
}

.row-gap-8 {
  row-gap: 32px;
}

.row-gap-10 {
  row-gap: 40px;
}


.m-0 {
  margin: 0;
}

.ml-4 {
  margin-left: 16px;
}

.ml-5 {
  margin-left: 20px;
}

.ml-6 {
  margin-left: 24px;
}

.ml-8 {
  margin-left: 32px;
}

.ml-10 {
  margin-left: 40px;
}

.ml-12 {
  margin-left: 48px;
}

.mr-auto {
  margin-right: auto;
}

.mt-auto {
  margin-top: auto;
}

.mr-6 {
  margin-right: 24px;
}

.mr-5 {
  margin-right: 20px;
}

.mr-10 {
  margin-right: 40px;
}

.mr-12 {
  margin-right: 48px;
}

.mr-8 {
  margin-right: 32px;
}

.mr-7 {
  margin-right: 28px;
}

.mr-4 {
  margin-right: 16px;
}

.ml-2 {
  margin-left: 8px;
}

.ml-1 {
  margin-left: 4px;
}

.ml-3 {
  margin-left: 12px;
}

.ml-4 {
  margin-left: 16px;
}

.ml-6 {
  margin-left: 24px;
}

.mr-1 {
  margin-right: 4px;
}

.mr-2 {
  margin-right: 8px;
}

.mr-3 {
  margin-right: 12px;
}

.mr-16 {
  margin-right: 16px;
}

.mx-1 {
  margin-left: 4px;
  margin-right: 4px;
}

.mx-2 {
  margin-left: 8px;
  margin-right: 8px;
}

.mx-3 {
  margin-left: 12px;
  margin-right: 12px;
}

.mx-4 {
  margin-left: 16px;
  margin-right: 16px;
}

.mx-6 {
  margin-left: 24px;
  margin-right: 24px;
}

.mx-8 {
  margin-left: 32px;
  margin-right: 32px;
}

.mx-10 {
  margin-left: 40px;
  margin-right: 40px;
}

.mxy-6 {
  margin: 24px;
}

.mxy-2 {
  margin: 8px;
}

.mxy-3 {
  margin: 12px;
}

.mxy-05 {
  margin: 2px;
}

.mt-1 {
  margin-top: 4px;
}

.mt-3 {
  margin-top: 12px;
}

.mt-5 {
  margin-top: 20px;
}

.mt-6 {
  margin-top: 24px;
}

.mt-7 {
  margin-top: 28px;
}

.mt-2 {
  margin-top: 8px;
}

.mt-4 {
  margin-top: 16px;
}

.mt-8 {
  margin-top: 32px;
}

.mt-10 {
  margin-top: 40px;
}

.mt-12 {
  margin-top: 48px;
}

.mt-14 {
  margin-top: 56px;
}

.mt-16 {
  margin-top: 64px;
}

.mt-20 {
  margin-top: 80px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 4px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-3 {
  margin-bottom: 12px;
}

.mb-4 {
  margin-bottom: 16px;
}

.mb-5 {
  margin-bottom: 20px;
}

.mb-6 {
  margin-bottom: 24px;
}

.mb-8 {
  margin-bottom: 32px;
}

.mb-10 {
  margin-bottom: 40px;
}

.mb-20 {
  margin-bottom: 80px;
}

.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.my-3 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.my-1 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.my-6 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.my-8 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.justify-start {
  justify-content: start;
}

.justify-center {
  justify-content: center;
}

.spacer {
  flex: 1 1 auto;
}

.pr-1 {
  padding-right: 4px;
}

.pr-2 {
  padding-right: 8px;
}

.pr-4 {
  padding-right: 16px;
}

.pr-5 {
  padding-right: 20px;
}

.pr-6 {
  padding-right: 24px;
}

.pl-2 {
  padding-left: 8px;
}

.pl-4 {
  padding-left: 16px;
}

.pl-5 {
  padding-left: 20px;
}

.pl-6 {
  padding-left: 24px;
}

.pl-7 {
  padding-left: 28px;
}

.pl-10 {
  padding-left: 40px;
}

.pt-6 {
  padding-top: 24px;
}

.pt-8{
  padding-top:32px;
}

.pt-2 {
  padding-top: 8px;
}

.pt-3 {
  padding-top: 12px;
}

.pt-4 {
  padding-top: 16px;
}

.pt-5 {
  padding-top: 20px;
}

.pt-10 {
  padding-top: 40px;
}

.pb-2 {
  padding-bottom: 8px;
}

.pb-4 {
  padding-bottom: 16px;
}

.pb-6 {
  padding-bottom: 24px;
}

.pb-8 {
  padding-bottom: 32px;
}

.pb-12 {
  padding-bottom: 48px;
}

.pb-3 {
  padding-bottom: 12px;
}

.pb-14 {
  padding-bottom: 64px;
}

.px-1 {
  padding-left: 4px;
  padding-right: 4px;
}

.px-5 {
  padding-left: 20px;
  padding-right: 20px;
}

.px-6 {
  padding-left: 24px;
  padding-right: 24px;
}

.px-3 {
  padding-left: 12px;
  padding-right: 12px;
}

.px-2 {
  padding-left: 8px;
  padding-right: 8px;
}

.px-4 {
  padding-left: 16px;
  padding-right: 16px;
}

.px-10 {
  padding-left: 40px;
  padding-right: 40px;
}

.px-12 {
  padding-left: 48px;
  padding-right: 48px;
}

.px-16 {
  padding-left: 64px;
  padding-right: 64px;
}

.py-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py-3 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.py-4 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-5 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-6 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.py-8 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.py-14 {
  padding-top: 56px;
  padding-bottom: 56px;
}

.pxy-1 {
  padding: 4px;
}

.pxy-1-2 {
  padding: 4px 8px;
}

.pxy-2 {
  padding: 8px;
}

.pxy-3 {
  padding: 12px;
}

.pxy-4 {
  padding: 16px;
}

.pxy-6 {
  padding: 24px;
}

.pxy-12 {
  padding: 48px;
}

.pxy-16 {
  padding: 64px;
}

.pxy-2-4 {
  padding: 8px 16px;
}

.pxy-3-4 {
  padding: 12px 16px;
}

.pxy-3-8 {
  padding: 12px 24px;
}

.pxy-4-2 {
  padding: 16px 8px;
}

.font-24 {
  font-size: 24px;
}

.font-22 {
  font-size: 22px;
}

.font-32 {
  font-size: 32px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-9 {
  font-size: 9px;
}

.font-40 {
  font-size: 40px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;

}

.m-auto {
  margin: auto;
}

.h-full {
  height: 100%;
}

.h-auto{
  height: auto;
}

.h-content {
  height: max-content;
}

.h-fit-content {
  height: fit-content;
}

.h-100-vh {
  height: 100vh;
}

.w-full {
  width: 100%;
}

.w-fit-content {
  width: fit-content;
}

.min-w-0 {
  min-width: 0;
}

.cursor * {
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}

.move-cursor {
  cursor: move;
}

.menu {
  border-radius: 8px;
  box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.1);
  user-select: none;
}

.no-select {
  user-select: none;
}

.text-wrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 40px;
  overflow: hidden;
}

.nowrap {
  white-space: nowrap;
}

.wrap {
  flex-wrap: wrap;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-auto {
  overflow: auto;
}

.justify-end {
  justify-content: flex-end;
}

.font-12 {
  font-size: 12px;
}

.font-10 {
  font-size: 10px;
}

.font-20 {
  font-size: 20px;
}

.search-border {
  border: 1px solid #d2e5ed;
  border-radius: 8px;
}

.text-underline {
  text-decoration: underline;
}

.text-decoration-none {
  text-decoration: none;
}

.o05 {
  opacity: 0.5;
}

.o07 {
  opacity: 0.7;
}

.o1 {
  opacity: 1;
}

.o03 {
  opacity: 0.3;
}

.radius-1 {
  border-radius: 4px;
}

.radius-2 {
  border-radius: 8px;
}

.radius-3 {
  border-radius: 12px;
}

.radius-4 {
  border-radius: 16px;
}

.radius-6 {
  border-radius: 24px;
}

.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

.fw-600{
  font-weight: 600;
}

.border-none {
  border: none;
}

.border-top {
  border-top: 1px solid ${({ theme }) => theme.colors.border};
}

.border-left {
  border-left: 1px solid ${({ theme }) => theme.colors.border};
}

.border-right {
  border-right: 1px solid ${({ theme }) => theme.colors.border};
}

.border-bottom {
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
}

.border {
  border: 1px solid ${({ theme }) => theme.colors.border};
}


.one-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.fit-content {
  height: fit-content;
}

.grab-cursor * {
  cursor: grabbing;
}

.grab-cursor {
  cursor: grabbing;
}

.move-cursor * {
  cursor: move;
}

.move-cursor {
  cursor: move;
}

.default-cursor * {
  cursor: default;
}

.default-cursor {
  cursor: default;
}

.new-version-popup {
  top: 0;
  left: 0;
  z-index: 30;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.modalBackground};
}

.refresh-view {
  height: 428px;
  width: 456px;

  .refresh-btn {
    height: 48px;
    background: #40f1de;
    border-radius: 8px;
  }
}

.medium-btn {
  height: 32px;
  border-radius: 10px;
}

* :focus-visible {
  outline: none;
}

* ::-webkit-scrollbar {
  width: 10px;
  height: 100%;
  background: transparent;
  border-radius:100px;
}

::-webkit-scrollbar-track {
  border-radius: 100px;
  padding: 0px 2px;
}
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #BFC4C8;
    border-radius: 100px;
    max-width: 7px;
    width:7px;
    cursor: pointer;
  }

.two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.three-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fit-content {
  height: fit-content;
}

.password-mask {
  text-security: disc; /* IE/Safari */
  -moz-text-security: disc; /* FireFox */
  -webkit-text-security: disc; /* Chrome/Safari  */
}
.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

.text-overflow-2{
  display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
}

.break-word{
  word-break:break-word;
}

.display-none{
  display: none;
}

.user-select-none {
  label {
    user-select: none !important;
  }
}

.main-background-color {
  background-color: ${({ theme }) => theme.colors.steps_background};
}

.background-color {
  background-color: ${({ theme }) => theme.colors.background};
}

.white-background {
  background-color: ${({ theme }) => theme.colors.white};
}

.white-text {
  color: ${({ theme }) => theme.colors.white};
}

.error-text {
  color: ${({ theme }) => theme.colors.pink};
}

.error-border {
  border-color: ${({ theme }) => theme.colors.pink};

  :hover {
    border-color: ${({ theme }) => theme.colors.pink};
  }
}

.error-background {
  background-color: ${({ theme }) => theme.colors.pink};
}

.required-star {
  color: ${({ theme }) => theme.colors.pink};
}


.light-pink-background {
    background-color: ${({ theme }) => theme.colors.pink_light};
  }
`;
