import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as DirectDebit } from '../../assets/images/DD.svg';
import { ReactComponent as LeftIcon, ReactComponent as RightIcon } from '../../assets/images/arrow.svg';
import { OrganisationContext } from '../../context/organisationContext';
import Box from '../elements/box';
import Button from '../elements/button/button';
import Checkbox from '../elements/checkbox';

const PaymentInfo = ({ payment, setPayment, onSubmit, paymentError = {} }) => {
  const { t } = useTranslation();
  const { getComponentByLexicon } = useContext(OrganisationContext);

  const vendorPhone = getComponentByLexicon('vendor.phone');

  return (
    <PaymentInfoWrapper className="user-select-none">
      <Box title={t('YOUR_LOTTERY_ENTRIES')}>
        <div className="payment-details w-full mt-8 col-gap-10 row-gap-8">
          <div className="flex flex-1 items-center col-gap-4">
            <Checkbox
              is_rounded={true}
              checked={payment.direct_debit}
              onChange={() => setPayment({ ...payment, direct_debit: true })}
              error={paymentError.direct_debit && !payment.direct_debit}
            />
            <DirectDebit className="flex flex-1 direct-debit-icon" width={80} />
            <p className="roboto-regular-text main-grey-text font-12">{t('DIRECT_DEBIT_INFORMATION')}</p>
          </div>
          <div className="flex items-center col-gap-4">
            <div className="flex-column radius-4 border pxy-2 lottery-entries">
              <label className="regular-text main-grey-text font-12 mb-2 pl-2">{t('LOTTERY_ENTRIES')}</label>
              <div className="flex">
                <div
                  className="flex items-center justify-center cursor radius-4 background-color action-icon-container"
                  onClick={() =>
                    setPayment({ ...payment, entries: parseInt(payment.entries - 1 < 1 ? 1 : payment.entries - 1) })
                  }>
                  <LeftIcon className="rotate-180" />
                </div>
                <label className="roboto-regular-text font-24 flex-1 flex item-center justify-center">
                  {payment.entries}
                </label>
                <div
                  className="flex items-center justify-center cursor radius-4 background-color action-icon-container"
                  onClick={() =>
                    setPayment({ ...payment, entries: parseInt(payment.entries + 1 > 5 ? 5 : payment.entries + 1) })
                  }>
                  <RightIcon />
                </div>
              </div>
            </div>
            <div className="flex-column row-gap-1">
              <label className="roboto-regular-text font-12">{t('WANT_MORE_THAN_5_ENTRIES')}</label>
              <label className="roboto-regular-text font-12 flex items-center">
                {t('CALL_US_ON')}
                {vendorPhone && (
                  <span className="roboto-medium-text white-text ml-1 px-1 radius-1 phone-background">
                    {vendorPhone.name}
                  </span>
                )}
              </label>
            </div>
          </div>
          <div className="flex-column">
            <div className="flex-column px-4 py-2 background-color radius-2">
              <label className="regular-text font-12 main-grey-text">{t('INITIAL_PAYMENT')}</label>
              <label className="roboto-regular-text font-32 mt-1">
                £{parseFloat(6.34 * payment.entries).toFixed(2)}
              </label>
            </div>
            <label className="roboto-regular-text font-12 mt-2">{t('INITIAL_PAYMENT_INFORMATION')}</label>
          </div>
          <div className="flex-column">
            <div className="flex-column px-4 py-2 background-color radius-2">
              <label className="regular-text font-12 main-grey-text">{t('RECURRING_PAYMENT')}</label>
              <label className="roboto-regular-text font-32 mt-1">
                £{parseFloat(4.34 * payment.entries).toFixed(2)} <span className="font-24">{t('A_MONTH')}</span>
              </label>
            </div>
            <label className="roboto-regular-text font-12 mt-2">{t('RECURRING_PAYMENT_INFORMATION')}</label>
          </div>
          <div className="flex items-center">
            <Checkbox
              checked={payment.terms_conditions}
              onChange={value => setPayment({ ...payment, terms_conditions: value })}
              error={paymentError.terms_conditions && !payment.terms_conditions}
            />
            <label className="roboto-regular-text ml-2">
              {t('I_HAVE_READ_AND_ACCEPRTED_THE')}
              <a
                className="roboto-regular-text field-accent-color-text text-decoration-none ml-1"
                href="https://zenlottery.com/lottery-rules/"
                target="_blank"
                rel="noreferrer">
                {t('TERMS_AND_CONDITIONS')}
              </a>
            </label>
          </div>
          <Button
            className="primary custom-button-primary w-full"
            size="medium"
            label={t('CONTINUE_TO_PAYMENT')}
            borderRadius="0px"
            onClick={() => onSubmit()}
          />
        </div>
      </Box>
    </PaymentInfoWrapper>
  );
};

export const PaymentInfoWrapper = styled.div`
  .direct-debit-icon {
    max-width: 80px;
    min-width: 80px;
  }
  .payment-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .payment-value-container {
    min-width: 160px;
    background-color: ${({ theme }) => theme.colors.border};
  }

  .lottery-entries {
    min-width: 130px;
    min-height: 72px;
  }

  .action-icon-container {
    height: 24px;
    width: 24px;
    border-radius: 50%;
  }

  .phone-background {
    background-color: ${({ theme }) => theme.colors.field_accent_color};
  }

  @media (max-width: 961px) {
    .payment-debit-info {
      flex-direction: column;
      align-items: flex-start;

      .lottery-entries {
        margin-left: 0;
      }
    }
    .payment-details {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export default PaymentInfo;
