import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { OrganisationContext } from '../../context/organisationContext';
import Box from '../elements/box';
import Button from '../elements/button/button';

const RegistrationComplete = ({
  contact,
  address,
  payment,
  debitRegistration,
  onBack,
  onSubmit,
  isSubmittingForm = false,
}) => {
  const { t } = useTranslation();

  const { getComponentByLexicon } = useContext(OrganisationContext);

  const { title = '', forename = '', surname = '', email = '', mobile = '', home = '' } = contact;
  const { formatted_address = '' } = address;
  const { entries = '', direct_debit = false } = payment;
  const { date = '', accountHolderName = '', accountNumber = '', sortCode = '' } = debitRegistration;

  const vendorReviewInfo = getComponentByLexicon('vendor.review_info');
  const vendorConfirmation = getComponentByLexicon('vendor.confirmation');
  const vendorEmail = getComponentByLexicon('vendor.email')?.name || '';
  const vendorPhone = getComponentByLexicon('vendor.phone')?.name || '';
  const paymentConfirmationName = getComponentByLexicon('vendor.bank_statement')?.name || '';

  return (
    <RegistrationCompleteWrapper>
      <Box className="contact-wrapper" title={t('REVIEW_YOUR_INFORMATION')}>
        {vendorReviewInfo ? (
          <label className="roboto-regular-text mt-2 mr-10 line-height-20px">{vendorReviewInfo.name}</label>
        ) : (
          <label className="roboto-regular-text mt-2 mr-10 line-height-20px">
            {t('VENDOR_REVIEW_LINE', { vendor_email: vendorEmail, vendor_phone: vendorPhone })}
          </label>
        )}
        <div className="flex-column w-full mt-6 registration-details col-gap-6 row-gap-6">
          <div className="card pxy-6 border radius-1">
            <label className="roboto-medium-text font-16">{t('YOUR_REGISTRATION_DETAILS')}</label>
            <div className="mt-6 registration-details-data col-gap-4 row-gap-4">
              <label className="roboto-regular-text main-grey-text">{t('FULL_NAME')}:</label>
              <label className="roboto-regular-text word-break-break-word">
                {title} {forename} {surname}
              </label>
              <label className="roboto-regular-text main-grey-text">{t('EMAIL')}:</label>
              <label className="roboto-regular-text word-break-break-word">{email || '-'}</label>
              <label className="roboto-regular-text main-grey-text">{t('MOBILE_PHONE')}:</label>
              <label className="roboto-regular-text word-break-break-word">{mobile ? `+44 ${mobile}` : '-'}</label>
              <label className="roboto-regular-text main-grey-text">{t('HOME_PHONE')}:</label>
              <label className="roboto-regular-text word-break-break-word">{home || '-'}</label>
              <label className="roboto-regular-text main-grey-text">{t('ADDRESS')}:</label>
              <label className="roboto-regular-text word-break-break-word">{formatted_address || '-'}</label>
            </div>
          </div>
          <div className="card pxy-6 border radius-1">
            <label className="roboto-medium-text font-16">{t('YOUR_PAYMENT_DETAILS')}</label>
            <div className="mt-6 registration-details-data col-gap-4 row-gap-4">
              <label className="roboto-regular-text main-grey-text">{t('PYAMENT_METHOD')}:</label>
              <label className="roboto-regular-text data-values word-break-break-word">
                {direct_debit ? 'Direct Debit' : '-'}
              </label>

              <label className="roboto-regular-text main-grey-text">{t('NAME_ON_ACCOUNT')}:</label>
              <label className="roboto-regular-text word-break-break-word">{accountHolderName}</label>

              <label className="roboto-regular-text main-grey-text">{t('ACCOUNT_NUMBER')}:</label>
              <label className="roboto-regular-text word-break-break-word">{accountNumber}</label>

              <label className="roboto-regular-text main-grey-text">{t('SORT_CODE')}:</label>
              <label className="roboto-regular-text word-break-break-word">{sortCode.replaceAll('-', '')}</label>

              <label className="roboto-regular-text main-grey-text">{t('CHANCES_PER_DRAW')}:</label>
              <label className="roboto-regular-text word-break-break-word">{entries}</label>

              <label className="roboto-regular-text main-grey-text">{t('INITIAL_PAYMENT')}:</label>
              <label className="roboto-regular-text word-break-break-word">
                £{parseFloat(6.34 * entries).toFixed(2)}
              </label>

              <label className="roboto-regular-text main-grey-text">{t('REGULAR_PAYMENT')}:</label>
              <label className="roboto-regular-text word-break-break-word">
                £{parseFloat(4.34 * entries).toFixed(2)}
              </label>

              <label className="roboto-regular-text main-grey-text">{t('FIRST_PAYMENT_DATE')}:</label>
              <label className="roboto-regular-text word-break-break-word">
                {date ? moment(date * 1000).format('DD/MM/YYYY') : '-'}
              </label>
            </div>
          </div>
          {vendorConfirmation ? (
            <label className="roboto-regular-text span-2 line-height-20px">{vendorConfirmation.name}</label>
          ) : (
            <label className="roboto-regular-text span-2 line-height-20px">
              {t('VENDOR_CONFIRMATION_LINE', { confirmation_name: paymentConfirmationName })}
            </label>
          )}
          <Button
            label={t('BACK')}
            className="secondary custom-button-secondary mt-2"
            borderRadius="0px"
            onClick={() => onBack()}
            disabled={isSubmittingForm}
          />
          <Button
            label={t('SUBMIT')}
            className="primary custom-button-primary mt-2"
            borderRadius="0px"
            onClick={() => onSubmit()}
            disabled={isSubmittingForm}
          />
        </div>
      </Box>
    </RegistrationCompleteWrapper>
  );
};

export const RegistrationCompleteWrapper = styled.div`
  .registration-details-data,
  .registration-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .span-2 {
    grid-column: span 2;
  }

  @media (max-width: 961px) {
    .registration-details {
      display: flex;
    }
  }
`;

export default RegistrationComplete;
