import { createSlice } from '@reduxjs/toolkit';
import api from '../services/index';

export const formSlice = createSlice({
  name: 'form',
  initialState: {},
  reducers: {},
});

export const getFormDetails = payload => async () => {
  try {
    const { form_id } = payload;
    const { data } = await api.get(`/self-service/forms/${form_id}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createContact = payload => async () => {
  try {
    const { api_key, request } = payload;
    const { data } = await api.post(`/contacts`, request, { headers: { api_key } });
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createPaymentMethod = payload => async () => {
  try {
    const { contactId, api_key, request } = payload;
    const { data } = await api.post(`/contacts/${contactId}/payment_methods`, request, { headers: { api_key } });
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createPaymentMethodSchedules = payload => async () => {
  try {
    const { paymentMethodId, api_key, request } = payload;
    const { data } = await api.post(`/payment_methods/${paymentMethodId}/schedules`, request, { headers: { api_key } });
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFormPanels = payload => async () => {
  try {
    const { api_key, form_id } = payload;
    const { data } = await api.get(`/self-service/forms/${form_id}/panels`, { headers: { api_key } });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFormPanelWidgets = payload => async () => {
  try {
    const { api_key, panel_id, params = {} } = payload;
    const { data } = await api.get(`/self-service/panels/${panel_id}/widgets`, { headers: { api_key }, params });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createFormSession = payload => async dispatch => {
  try {
    const { request, api_key } = payload;
    const { data } = await api.post(`/form_sessions`, request, { headers: { api_key } });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateFormSession = payload => async dispatch => {
  try {
    const { id, request, api_key } = payload;
    const { data } = await api.put(`/form_sessions/${id}`, request, { headers: { api_key } });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const submitFormSessionData = payload => async dispatch => {
  try {
    const { id, request, api_key } = payload;
    const { data } = await api.put(`/form_sessions/${id}/submission_data`, request, { headers: { api_key } });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const verifyBankDetails = payload => async dispatch => {
  try {
    const { request, api_key, params } = payload;
    const { data } = await api.post(`/bank_verify`, request, { headers: { api_key }, params: params });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const {} = formSlice.actions;
export default formSlice.reducer;
