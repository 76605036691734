import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { getLanguage } from '../helpers/i18n';
import { TRANSLATIONS_EN_GB } from './en-gb/translation';
import { TRANSLATIONS_EN_US } from './en-us/translation';
import { TRANSLATIONS_WELSH } from './welsh/translation';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en_gb: {
        translation: TRANSLATIONS_EN_GB,
      },
      en_us: {
        translation: TRANSLATIONS_EN_US,
      },
      cy: {
        translation: TRANSLATIONS_WELSH,
      },
    },
    lng: getLanguage(),
    fallbackLng: 'en_gb',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
