import React, { useEffect } from 'react';
import Select, { components } from 'react-select';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as ArrowUpDownIcon } from '../../../assets/images/arrow-up-down.svg';
import { fontObject } from '../../../styles/fonts.styled';

const Option = props => {
  const { innerProps, data, isSelected } = props;

  return (
    <div
      className={`flex items-center w-full pxy-2 radius-1 cursor option-wrapper ${isSelected ? 'selected' : ''}`}
      {...innerProps}>
      <span className={`flex-1 regular-text font-16 option-text ${isSelected && 'semibold-text'}`}>{data.label}</span>
    </div>
  );
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowUpDownIcon />
    </components.DropdownIndicator>
  );
};

const Dropdown = ({
  options = [],
  placeholder = '',
  isSearchable = false,
  onChange = () => {},
  selectedValue = null,
  isMulti = false,
  name = '',
  is_required = false,
  is_autofill,
  updateOnLoad = false,
  className = '',
  error = false,
  error_text = '',
  autofill = 'off',
}) => {
  const theme = useTheme();

  useEffect(() => {
    if (updateOnLoad) {
      onChange(null);
    }
  }, []);

  const dropdownStyle = {
    control: (baseStyles, { selectProps: { menuIsOpen } }) => ({
      ...baseStyles,
      borderColor: error ? theme.colors.pink : menuIsOpen ? theme.colors.field_accent_color : theme.colors.border,
      borderRadius: '6px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minHeight: '40px',
      cursor: 'pointer',
      boxShadow: 'none',

      '&:hover': {
        borderColor: error ? theme.colors.pink : menuIsOpen ? theme.colors.field_accent_color : theme.colors.blue_hover,
      },
    }),
    placeholder: baseStyles => ({
      ...baseStyles,
      fontFamily: theme.font ? fontObject[theme.font]?.['Regular'] : 'Lato Regular',
      color: theme.colors.main_grey,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: baseStyles => ({
      ...baseStyles,
      color: theme.colors.black_text,
    }),
    valueContainer: baseStyles => ({
      ...baseStyles,
      fontFamily: theme.font ? fontObject[theme.font]?.['Regular'] : 'Lato Regular',
      padding: '4px 16px',
    }),
    menu: baseStyles => ({
      ...baseStyles,
      borderRadius: '6px',
      padding: '8px',
      zIndex: 11,
    }),
    menuList: baseStyles => ({
      ...baseStyles,
      padding: '0px',
    }),
    clearIndicator: () => ({
      display: 'none',
    }),
    noOptionsMessage: baseStyles => ({
      ...baseStyles,
      fontFamily: theme.font ? fontObject[theme.font]?.['Regular'] : 'Lato Regular',
    }),
  };

  return (
    <DropdownWrapper className={`w-full ${className}`}>
      <div className="mb-1">
        <span className="regular-text main-grey-text font-12">{name}</span>
        {is_required && <span className="required-star ml-1">*</span>}
      </div>
      <Select
        options={options}
        placeholder={placeholder}
        isSearchable={isSearchable}
        onChange={option => {
          onChange(option);
        }}
        isMulti={isMulti}
        isDisabled={is_autofill}
        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={false}
        value={selectedValue}
        styles={dropdownStyle}
        menuPlacement="auto"
        inputProps={{ autoComplete: autofill }}
        components={{
          Option,
          DropdownIndicator,
        }}
      />
      {error && error_text && (
        <div>
          <span className="roboto-regular-text font-12 pink-text">{error_text}</span>
        </div>
      )}
    </DropdownWrapper>
  );
};

export const DropdownWrapper = styled.div`
  .option-wrapper {
    &:hover {
      background-color: ${({ theme }) => theme.colors.dropdown_option_hover};
      .option-text {
        color: ${({ theme }) => theme.colors.field_accent_color_text};
      }
    }
  }

  .selected {
    background-color: ${({ theme }) => theme.colors.dropdown_option_hover};
    .option-text {
      color: ${({ theme }) => theme.colors.field_accent_color_text};
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.dropdown_option_hover};
    }
  }
  * ::-webkit-scrollbar {
    width: 4px;
    height: 100%;
    background: transparent;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 100px;
    padding: 0px 2px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #bfc4c8;
    border-radius: 100px;
    max-width: 2px;
    width: 2px;
    cursor: pointer;
  }
`;

export default Dropdown;
