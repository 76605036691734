import React from 'react';
import styled from 'styled-components';

const Box = ({ children, title, className = '', isSmallBox = false }) => {
  return isSmallBox ? (
    <SmallBoxWrapper>
      <label className="flex items-end font-24 antipasto-semibold-text blue-text text-center small-box-title">
        {title}
      </label>
      {children}
    </SmallBoxWrapper>
  ) : (
    <BoxWrapper className={`flex-column items-start ${className}`}>
      <label className="font-32 antipasto-semibold-text blue-text">{title}</label>
      {children}
    </BoxWrapper>
  );
};

const BoxWrapper = styled.div`
  width: max(840px, 60vw);
  padding: 56px 72px 64px;

  @media (max-width: 961px) {
    width: 90vw;
    padding: 56px 64px 64px;
  }

  @media (max-width: 641px) {
    width: 90vw;
    padding: 56px 32px 64px;
  }
`;

const SmallBoxWrapper = styled.div`
  width: max(840px, 60vw);
  padding: 0 72px 64px;

  @media (max-width: 961px) {
    width: 90vw;
    padding: 0 64px 64px;
  }

  @media (max-width: 641px) {
    width: 90vw;
    padding: 0 32px 64px;
  }

  .small-box-title {
    ::after {
      content: '';
      flex: 1 1;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.border};
      margin-bottom: 4px;
    }
  }
`;

export default Box;
