import { createGlobalStyle } from 'styled-components';
import AntipastoMedium from '../assets/fonts/Antipasto-Medium.ttf';
import AntipastoRegular from '../assets/fonts/Antipasto-Regular.ttf';
import AntipastoSemibold from '../assets/fonts/Antipasto-Semibold.ttf';
import BloomSpeakOTBold from '../assets/fonts/BloomSpeakOT-Bold.otf';
import BloomSpeakOTHeavy from '../assets/fonts/BloomSpeakOT-Heavy.otf';
import BloomSpeakOTLight from '../assets/fonts/BloomSpeakOT-Light.otf';
import BloomSpeakOTRegular from '../assets/fonts/BloomSpeakOT-Regular.otf';
import LatoBold from '../assets/fonts/Lato-Bold.ttf';
import LatoLight from '../assets/fonts/Lato-Light.ttf';
import LatoMedium from '../assets/fonts/Lato-Medium.ttf';
import LatoRegular from '../assets/fonts/Lato-Regular.ttf';
import LatoSemibold from '../assets/fonts/Lato-Semibold.ttf';
import RobotoBold from '../assets/fonts/Roboto-Bold.ttf';
import RobotoLight from '../assets/fonts/Roboto-Light.ttf';
import RobotoMedium from '../assets/fonts/Roboto-Medium.ttf';
import RobotoRegular from '../assets/fonts/Roboto-Regular.ttf';
import RobotoThin from '../assets/fonts/Roboto-Thin.ttf';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  @font-face {
    font-family: 'Lato Light';
    src: local('Lato Light'), url(${LatoLight}) format('truetype');
  }

  @font-face {
    font-family: 'Lato Regular';
    src: local('Lato Regular'), url(${LatoRegular}) format('truetype');
  }

  @font-face {
    font-family: 'Lato Medium';
    src: local('Lato Medium'), url(${LatoMedium}) format('truetype');
  }

  @font-face {
    font-family: 'Lato Semibold';
    src: local('Lato Semibold'), url(${LatoSemibold}) format('truetype');
  }

  @font-face {
    font-family: 'Lato Bold';
    src: local('Lato Bold'), url(${LatoBold}) format('truetype');
  }

  @font-face {
    font-family: 'Roboto Bold';
    src: local('Roboto Bold'), url(${RobotoBold}) format('truetype');
  }

  @font-face {
    font-family: 'Roboto Light';
    src: local('Roboto Light'), url(${RobotoLight}) format('truetype');
  }

  @font-face {
    font-family: 'Roboto Medium';
    src: local('Roboto Medium'), url(${RobotoMedium}) format('truetype');
  }

  @font-face {
    font-family: 'Roboto Regular';
    src: local('Roboto Regular'), url(${RobotoRegular}) format('truetype');
  }

  @font-face {
    font-family: 'Roboto Thin';
    src: local('Roboto Thin'), url(${RobotoThin}) format('truetype');
  }

  @font-face {
    font-family: 'Antipasto Regular';
    src: local('Antipasto Regular'), url(${AntipastoRegular}) format('truetype');
  }

  @font-face {
    font-family: 'Antipasto Medium';
    src: local('Antipasto Medium'), url(${AntipastoMedium}) format('truetype');
  }

  @font-face {
    font-family: 'Antipasto Semibold';
    src: local('Antipasto Semibold'), url(${AntipastoSemibold}) format('truetype');
  }

  @font-face {
    font-family: 'BloomSpeakOT Regular';
    src: local('BloomSpeakOT Regular'), url(${BloomSpeakOTRegular}) format('opentype');
  }

  @font-face {
    font-family: 'BloomSpeakOT Light';
    src: local('BloomSpeakOT Light'), url(${BloomSpeakOTLight}) format('opentype');
  }

  @font-face {
    font-family: 'BloomSpeakOT Bold';
    src: local('BloomSpeakOT Bold'), url(${BloomSpeakOTBold}) format('opentype');
  }

  @font-face {
    font-family: 'BloomSpeakOT Heavy';
    src: local('BloomSpeakOT Heavy'), url(${BloomSpeakOTHeavy}) format('opentype');
  }


  * {
    box-sizing: border-box;
  }
`;

export default GlobalStyle;
