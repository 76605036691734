import React from 'react';
import styled from 'styled-components';

const Checkbox = ({
  name,
  is_required = false,
  onChange = () => {},
  checked = false,
  error,
  is_rounded = false,
  className = '',
}) => {
  return (
    <CheckboxWrapper className={`flex items-center ${className}`}>
      <input
        className={`cursor checkbox ${is_rounded ? 'rounded' : ''} ${checked ? 'checked' : ''} ${
          error ? 'required-error' : ''
        }`}
        type="checkbox"
        checked={checked}
        onChange={() => onChange(!checked)}
      />
      {name && (
        <div className="ml-2 flex-1">
          <span className="roboto-regular-text font-16 name-text">{name}</span>
          {is_required && <span className="required-star ml-1">*</span>}
        </div>
      )}
    </CheckboxWrapper>
  );
};

const CheckboxWrapper = styled('div')`
  .checkbox {
    appearance: none;
    min-width: 24px;
    min-height: 24px;
    outline: none;
    margin: 0;
    border: 1px solid ${({ theme }) => theme.colors.border};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
  }

  .rounded {
    border-radius: 50%;
  }

  .checked {
    background-color: ${({ theme }) => theme.colors.checkbox_checked};
    border: 1px solid ${({ theme }) => theme.colors.border};
    outline: 6px solid white;
    outline-offset: -7px;
  }

  .required-error {
    border-color: ${({ theme }) => theme.colors.pink};
  }
`;

export default Checkbox;
