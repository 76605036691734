export const TRANSLATIONS_EN_US = {
  JOIN_NOW: 'Join now!',
  AGE_VERIFICATION: 'Age verification',
  PLEASE_ENTER_DATE_OF_BIRTH: 'Please enter your date of birth',
  MUST_BE_ABOVE_18: '(Please note you must be 18 or over to play)',
  DAY: 'Day',
  MONTH: 'Month',
  YEAR: 'Year',
  PLEASE_ENTER_VALID_DATE_OF_BIRTH: 'Please enter valid date of birth',
  MUST_BE_18_OR_OVER: 'You must be at least 18 or over',
  YOUR_CONTACT_INFO: 'Your contact info',
  TITLE: 'Title',
  NAME: 'Name',
  SURNAME: 'Surname',
  EMAIL: 'Email',
  MOBILE_PHONE: 'Mobile phone',
  HOME_PHONE: 'Home phone',
  MOBILE_PHONE_NUMBER: 'Mobile phone number',
  HOME_PHONE_NUMBER: 'Home phone number',
  YOUR_ADDRESS_DETAILS: 'Your address details',
  ADDRESS: 'Address',
  ADDRESS_LINE_1: 'Address line 1',
  ADDRESS_LINE_2: 'Address line 2',
  ADDRESS_LINE_3: 'Address line 3',
  TOWN_CITY: 'Town / City',
  COUNTY: 'County',
  POSTCODE: 'Postcode',
  YOUR_CONTACT_PREFERENCES: 'Your contact preferences',
  SELECT_HOW_TO_KEEP_IN_TOUCH: 'Please select how we can keep in touch with you.',
  RECEIVE_EMAIL: 'Email',
  RECEIVE_PHONE_CALL: 'Phone call',
  RECEIVE_SMS_MESSAGES: 'SMS-message',
  VENDOR_PRODUCT_SERVICE_INFO:
    '{{vendor_name}} would like to keep in touch and tell you about how your support is helping and their future work. Please tick below if you would like to hear from them by:',
  LOTTERY_CONSET_LINES:
    'This lottery is administered by BriteVox Limited t/a ZenterPrize. From time to time, ZenterPrize would like to pass your information to carefully selected partners to give you exclusive offers and discounts. Please tick below if you would like to hear about these by:',
  DIRECT_DEBIT_INFORMATION:
    'Paying by Direct Debit means your balance will top-up automatically so you never miss a draw.',
  NAME_ON_ACCOUNT: 'Name on account',
  CONTINUE_TO_PAYMENT: 'Continue to payment',
  PLEASE_SELECT_TITLE: 'Please select title',
  PLEASE_ENTER_FIRST_NAME: 'Please enter your first name',
  PLEASE_ENTER_SUR_NAME: 'Please enter your surname',
  PLEASE_ENTER_EMAIL: 'Please enter your email',
  PLEASE_ENTER_VALID_EMAIL: 'Please enter your valid email',
  PLEASE_ENTER_MOBILE_OR_HOME: 'Please enter either a mobile or a home phone number',
  PLEASE_ENTER_ADDRESS: 'Please enter your address',
  ENTER_YOUR_ADDRESS: 'Enter your address',
  ENTER: 'Enter',
  NAME_PLACEHOLDER: 'e.g. John',
  SURNAME_PLACEHOLDER: 'e.g. Smith',
  ENTER_EMAIL: 'Enter email',
  LOTTERY_ENTRIES: 'Lottery entries',
  WANT_MORE_THAN_5_ENTRIES: 'Want more than 5 entries?',
  CALL_US_ON: 'Call us on',
  INITIAL_PAYMENT: 'Initial payment',
  RECURRING_PAYMENT: 'Recurring payment',
  A_MONTH: 'a month',
  I_HAVE_READ_AND_ACCEPRTED_THE: 'I have read and accepted the',
  TERMS_AND_CONDITIONS: 'terms and conditions',
  YOUR_LOTTERY_ENTRIES: 'Your lottery entries',
  SECURE_DIRECT_DEBIT_REGISTRATION: 'Secure Direct Debit registration',
  SELECT_DATE_OF_FIRST_PAYMENT: 'Select the date of your first payment',
  ACCOUNT_HOLDER_NAME: "Account holder's name",
  SORT_CODE: 'Sort code',
  SORT_CODE_PLACEHOLDER: 'e.g. 11-11-11',
  ACCOUNT_NUMBER: 'Account number',
  ACCOUNT_NUMBER_PLACEHOLDER: 'e.g. 12345678',
  AUTHORIZED_TO_SET_DIRECT_DEBIT_ON_ACCOUNT: 'I am authorised to set up a Direct Debit on this account',
  YEARS_18_OLD_OR_OVER: 'I am 18 years old or over',
  INSTRUCTION_TO_BANK_OR_SOCIETY: 'Instruction to your Bank or Building Society',
  PAY_ZENLOTTERY:
    'Please pay L&Z Re {{direct_debit_label}}. Direct Debits from the account detailed in this Instruction are subject to the safeguards assured by the Direct Debit Guarantee. I understand that this Instruction may remain with L&Z Re {{direct_debit_label}} and, if so, details will be passed electronically to my Bank/Building Society.',
  SERVICE_USER_NUMBER: 'Service user number',
  DIRECT_DEBIT_GURANTEE: 'Direct Debit Guarantee',
  DIRECT_DEBIT_GURANTEE_PARAGRAPH_1:
    'This Guarantee is offered by all banks and building societies that accept instructions to pay Direct Debits.',
  DIRECT_DEBIT_GURANTEE_PARAGRAPH_2:
    'If there are any changes to the amount, date or frequency of your Direct Debit L&Z re {{direct_debit_label}} will notify you 3 working days in advance of your account being debited or as otherwise agreed. If you request L&Z re {{direct_debit_label}} to collect a payment, confirmation of the amount and date will be given to you at the time of the request.',
  DIRECT_DEBIT_GURANTEE_PARAGRAPH_3:
    'If an error is made in the payment of your Direct Debit, by L&Z re {{direct_debit_label}} or your bank or building society you are entitled to a full and immediate refund of the amount paid from your bank or building society-If you receive a refund you are not entitled to, you must pay it back when L&Z re {{direct_debit_label}} asks you to.',
  DIRECT_DEBIT_GURANTEE_PARAGRAPH_4:
    'You can cancel a Direct Debit at any time by simply contacting your bank or building society. Written confirmation may be required. Please also notify us.',
  BACK: 'Back',
  REVIEW: 'Review',
  REVIEW_YOUR_INFORMATION: 'Review your information',
  YOUR_REGISTRATION_DETAILS: 'Your registration details',
  FULL_NAME: 'Full name',
  YOUR_PAYMENT_DETAILS: 'Your payment details',
  PYAMENT_METHOD: 'Payment method',
  CHANCES_PER_DRAW: 'Chances per draw',
  REGULAR_PAYMENT: 'Regular payment',
  FIRST_PAYMENT_DATE: 'First payment date',
  SUBMIT: 'Submit',
  RESIDENT_OF_GREAT_BRITAIN: 'I am a resident of Great Britain (England, Scotland, Wales)',
  RECURRING_PAYMENT_INFORMATION:
    'Why 34p? Over the course of a year, they add up to cover all 52 draws. Without it, you would miss the 5th draw in any 5 draw month.',
  INITIAL_PAYMENT_INFORMATION:
    'Your first payment will be £2 higher per line than regular payments to avoid missing a draw in a 5 week month.',
  SOMETHING_WENT_WRONG_WHILE_CREATING_PAYMENT: 'Something went wrong while creating payment',
  YOUR_FORM_IS_SUBMITTED_SUCCESSFULLY: 'Your form is submitted successfully!',
  SOMETHING_WENT_WRONG_WHILE_SUBMITTING_FORM: 'Something went wrong while submitting form',
  PLEASE_FILL_REQUIRED_DATA: 'Please fill required data',
  PLEASE_READ_ACCEPT_TERMS_AND_CONDITIONS_TO_CONTINUE: 'Please read and accept the terms and conditions to continue',
  ERROR: 'Error',
  SUCCESS: 'Success',
  JANUARY: 'January',
  FEBRUARY: 'February',
  MARCH: 'March',
  APRIL: 'April',
  MAY: 'May',
  JUNE: 'June',
  JULY: 'July',
  AUGUST: 'August',
  SEPTEMBER: 'September',
  OCTOBER: 'October',
  NOVEMBER: 'November',
  DECEMBER: 'December',
  PAYMENT_DATE_FORMAT: '{{day}} {{month}}, {{year}}',
  VENDOR_REVIEW_LINE:
    'If everything looks good, click Submit to join the lottery. For any queries, contact {{vendor_email}} or call {{vendor_phone}}.',
  VENDOR_CONFIRMATION_LINE:
    'Confirmation will be sent to you within 3 working days or be received by you no later than 3 working days before the first collection. The name that will appear on your statement is “{{confirmation_name}}”.',
  'Keep Wales Tidy': 'Keep Wales Tidy',
};
