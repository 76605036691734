import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganisationContext } from '../../../context/organisationContext';
import ImageElement from '../image-element';

const HeaderImage = ({ formWidgets = [] }) => {
  const { components } = formWidgets?.[0] || {};
  const imageComponent = components?.find(component => component.component_type === 'IMAGE');
  const { image } = imageComponent || {};

  return <ImageElement src={image?.image?.url} height={image?.height} width={image?.width} />;
};

const Header = () => {
  const { t } = useTranslation();
  const { formWidgets } = useContext(OrganisationContext);

  return (
    <div className="flex-column items-center mt-14 mb-10 mx-6">
      <HeaderImage formWidgets={formWidgets} />
      <label className="antipasto-semibold-text font-32 mt-10 text-center mx-10">{t('JOIN_NOW')}</label>
    </div>
  );
};

export default Header;
