export const hardcodedFormIds = ['1dfacf64-71c5-407b-9d14-87338980f852', '10083006-3aa4-11ee-9881-42010a9a0008'];
export const integrationId = 'e48e9ded-40b6-4b86-973e-cb80a06f69cd';

export const hardCodedComponentLexicons = [
  'contact.entries',
  'contact.payment_frequency',
  'contact.payment_date',
  'contact.payment_day',
  'contact.initial_amount',
  'contact.payment_amount',
  'contact.payment_method.id',
  'contact.id',
  'contact.title',
  'contact.forename',
  'contact.surname',
  'contact.email',
  'contact.mobile',
  'contact.landline',
  'contact.address.line1',
  'contact.address.line2',
  'contact.address.line3',
  'contact.address.city',
  'contact.address.state',
  'contact.address.postcode',
  'contact.language',
];

import moment from 'moment';

const currentYear = moment().get('year');

export const specialCharRegEx = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
export const emailRegEx = /^[A-Za-z0-9][-A-Z-a-z0-9.!#$%&'*+-=?^_`{|}~\/]+@([-A-Z-a-z0-9]+\.)+[A-Za-z]{2,5}$/;

export const dayOptions = Array(31)
  .fill()
  .map((_, idx) => 1 + idx)
  .map(value => ({ label: value.toString(), value }));

export const monthOptions = [
  { label: 'January', value: 1, key: 'JANUARY' },
  { label: 'February', value: 2, key: 'FEBRUARY' },
  { label: 'March', value: 3, key: 'MARCH' },
  { label: 'April', value: 4, key: 'APRIL' },
  { label: 'May', value: 5, key: 'MAY' },
  { label: 'June', value: 6, key: 'JUNE' },
  { label: 'July', value: 7, key: 'JULY' },
  { label: 'August', value: 8, key: 'AUGUST' },
  { label: 'September', value: 9, key: 'SEPTEMBER' },
  { label: 'October', value: 10, key: 'OCTOBER' },
  { label: 'November', value: 11, key: 'NOVEMBER' },
  { label: 'December', value: 12, key: 'DECEMBER' },
];

export const yearOptions = Array(currentYear - 18 - 1900)
  .fill()
  .map((_, idx) => currentYear - 18 - idx)
  .map(value => ({ label: value.toString(), value }));

export const titleOptions = [
  { label: 'Mr', value: 'Mr' },
  { label: 'Mrs', value: 'Mrs' },
  { label: 'Ms', value: 'Ms' },
  { label: 'Miss', value: 'Miss' },
  { label: 'Dr', value: 'Dr' },
  { label: 'Mx', value: 'Mx' },
];

export const paymentFrequenceOptions = [
  { label: 'Monthly', value: 'MONTHLY' },
  { label: 'Quarterly', value: 'QUARTERLY' },
  { label: 'Yearly', value: 'YEARLY' },
];

export const chancesOptions = Array(30)
  .fill()
  .map((_, idx) => 1 + idx)
  .map(value => ({ label: `${value} chance${value > 1 ? 's' : ''}`, value }));
