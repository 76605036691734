export const TRANSLATIONS_WELSH = {
  ENGLISH: 'English',
  WELSH: 'Cymraeg',
  JOIN_NOW: 'Ymunwch nawr!',
  AGE_VERIFICATION: 'Dilysu oed',
  PLEASE_ENTER_DATE_OF_BIRTH: 'Rhowch eich dyddiad geni',
  MUST_BE_ABOVE_18: '(Noder mae’n rhaid i chi fod yn 18 oed neu’n hŷn i chwarae)',
  DAY: 'Diwrnod',
  MONTH: 'Mis',
  YEAR: 'Blwyddyn',
  PLEASE_ENTER_VALID_DATE_OF_BIRTH: 'Rhowch ddyddiad geni dilys',
  MUST_BE_18_OR_OVER: 'Mae’n rhaid i chi fod yn 18 oed neu’n hŷn',
  YOUR_CONTACT_INFO: 'Eich gwybodaeth gyswllt',
  TITLE: 'Teitl',
  NAME: 'Enw',
  SURNAME: 'Cyfenw',
  EMAIL: 'E-bost',
  MOBILE_PHONE: 'Rhif ffôn symudol',
  HOME_PHONE: 'Rhif ffôn cartref',
  MOBILE_PHONE_NUMBER: 'Rhif ffôn symudol',
  HOME_PHONE_NUMBER: 'Rhif ffôn cartref',
  YOUR_ADDRESS_DETAILS: 'Manylion eich cyfeiriad',
  ADDRESS: 'Cyfeiriad',
  ADDRESS_LINE_1: 'Llinell cyfeiriad 1',
  ADDRESS_LINE_2: 'Llinell cyfeiriad 2',
  ADDRESS_LINE_3: 'Llinell cyfeiriad 3',
  TOWN_CITY: 'Tref / Dinas',
  COUNTY: 'Sir',
  POSTCODE: 'Cod post',
  YOUR_CONTACT_PREFERENCES: 'Eich dewisiadau cyswllt',
  SELECT_HOW_TO_KEEP_IN_TOUCH: 'Dewiswch sut gallwn gadw mewn cysylltiad â chi.',
  RECEIVE_EMAIL: 'E-bost',
  RECEIVE_PHONE_CALL: 'Galwad ffôn',
  RECEIVE_SMS_MESSAGES: 'Neges SMS',
  VENDOR_PRODUCT_SERVICE_INFO:
    'Mae {{vendor_name}} yn dymuno cadw mewn cysylltiad â chi a rhoi gwybod i chi sut mae eich cymorth chi yn helpu eu gwaith i’r dyfodol. Ticiwch isod os hoffech glywed oddi wrthynt drwy:',
  LOTTERY_CONSET_LINES:
    'Gweinyddir y loteri hon gan BriteVox Limited t/a ZenterPrize. O bryd i’w gilydd, bydd ZenterPrize yn dymuno rhoi eich gwybodaeth i bartneriaid sydd wedi eu dethol yn ofalus i roi cynigion a gostyngiadau unigryw i chi. Ticiwch isod os hoffech glywed am y rhain drwy:',
  DIRECT_DEBIT_INFORMATION:
    'Mae talu trwy Ddebyd Uniongyrchol yn golygu yr ychwanegir at eich balans yn awtomatig fel na fyddwch byth yn colli loteri.',
  NAME_ON_ACCOUNT: 'Enw ar y cyfrif',
  CONTINUE_TO_PAYMENT: 'Parhau i wneud taliad',
  PLEASE_SELECT_TITLE: 'Dewiswch deitl',
  PLEASE_ENTER_FIRST_NAME: 'Rhowch eich enw cyntaf',
  PLEASE_ENTER_SUR_NAME: 'Rhowch eich cyfenw',
  PLEASE_ENTER_EMAIL: 'Rhowch eich e-bost',
  PLEASE_ENTER_VALID_EMAIL: 'Rhowch eich e-bost dilys',
  PLEASE_ENTER_MOBILE_OR_HOME: 'Rhowch rif ffôn symudol neu ffôn cartref',
  PLEASE_ENTER_ADDRESS: 'Ychwanegwch eich cyfeiriad',
  ENTER_YOUR_ADDRESS: 'Rhowch eich cyfeiriad',
  ENTER: 'Cyflwyno',
  NAME_PLACEHOLDER: 'e.e. John',
  SURNAME_PLACEHOLDER: 'e.e. Smith',
  ENTER_EMAIL: 'Rhowch eich e-bost',
  LOTTERY_ENTRIES: 'Cynigion loteri',
  WANT_MORE_THAN_5_ENTRIES: 'Eisiau mwy na 5 cynnig?',
  CALL_US_ON: 'Ffoniwch ni ar',
  INITIAL_PAYMENT: 'Taliad cychwynnol',
  RECURRING_PAYMENT: 'Taliad rheolaidd',
  A_MONTH: 'y mis',
  I_HAVE_READ_AND_ACCEPRTED_THE: 'Rwyf wedi darllen a derbyn y',
  TERMS_AND_CONDITIONS: 'telerau ac amodau',
  YOUR_LOTTERY_ENTRIES: 'Eich cynigion loteri',
  SECURE_DIRECT_DEBIT_REGISTRATION: 'Cofrestru Debyd Uniongyrchol yn Ddiogel',
  SELECT_DATE_OF_FIRST_PAYMENT: 'Dewiswch ddyddiad eich taliad cyntaf',
  ACCOUNT_HOLDER_NAME: 'Enw deiliad y cyfrif',
  SORT_CODE: 'Cod didoli',
  SORT_CODE_PLACEHOLDER: 'e.e. 11-11-11',
  ACCOUNT_NUMBER: 'Rhif y cyfrif',
  ACCOUNT_NUMBER_PLACEHOLDER: 'e.e. 12345678',
  AUTHORIZED_TO_SET_DIRECT_DEBIT_ON_ACCOUNT: 'Rwyf wedi fy awdurdodi i sefydu Debyd Uniongyrchol ar y cyfrif hwn',
  YEARS_18_OLD_OR_OVER: 'Rwyf yn 18 oed neu’n hŷn',
  INSTRUCTION_TO_BANK_OR_SOCIETY: 'Cyfarwyddiadau i’ch Banc neu Gymdeithas Adeiladu',
  PAY_ZENLOTTERY:
    'Talwch {{direct_debit_label}}. Mae Debydau Uniongyrchol o’r cyfrif hwn sydd wedi’u nodi yn y Cyfarwyddyd hwn yn amodol ar gamau diogelu sydd wedi eu sicrhau gan y Gwarant Debyd Uniongyrchol. Rwy’n deall y bydd y Cyfarwyddyd hwn yn parhau gyda {{direct_debit_label}} ac, os felly, bydd y manylion yn cael eu trosglwyddo’n electronig i fy nghyfrif Banc/Cymdeithas Adeiladu',
  SERVICE_USER_NUMBER: 'Rhif defnyddiwr y gwasanaeth',
  DIRECT_DEBIT_GURANTEE: 'Gwarant Debyd Uniongyrchol',
  DIRECT_DEBIT_GURANTEE_PARAGRAPH_1:
    'Mae’r Gwarant hwn yn cael ei gynnig gan bob banc a chymdeithas adeiladu sydd yn derbyn cyfarwyddiadau i dalu Debydau Uniongyrchol.',
  DIRECT_DEBIT_GURANTEE_PARAGRAPH_2:
    'Os oes unrhyw newidiadau i’r swm, dyddiad neu amlder eich Debyd Uniongyrchol, bydd {{direct_debit_label}} yn eich hysbysu 3 diwrnod gwaith ymlaen llaw y bydd debyd yn cael eich dynnu o’ch cyfrif neu fel y cytunwyd fel arall. Os byddwch yn gwneud cais i {{direct_debit_label}} loteri gasglu taliad, bydd cadarnhad o’r swm a’r dyddiad yn cael ei roi i chi ar adeg gwneud y cais.',
  DIRECT_DEBIT_GURANTEE_PARAGRAPH_3:
    'Os bydd gwall wrth dalu eich Debyd Uniongyrchol, gan {{direct_debit_label}} lottery neu eich banc neu gymdeithas adeiladu, bydd gennych hawl i ad-daliad llawn ar unwaith o’r swm a dalwyd o’ch banc neu gymdeithas adeiladu-Os byddwch yn derbyn ad-daliad nad oes gennych hawl iddo, mae’n rhaid i chi ei dalu yn ôl pan fydd {{direct_debit_label}} yn gofyn i chi wneud hynny.',
  DIRECT_DEBIT_GURANTEE_PARAGRAPH_4:
    'Gallwch ganslo Debyd Uniongyrchol unrhyw bryd trwy gysylltu â’ch banc neu gymdeithas adeiladu. Gall fod angen cadarnhad ysgrifenedig. Rhowch wybod i ni hefyd.',
  BACK: 'Yn ôl',
  REVIEW: 'Adolygu',
  REVIEW_YOUR_INFORMATION: 'Adolygu eich gwybodaeth',
  YOUR_REGISTRATION_DETAILS: 'Eich manylion cofrestru',
  FULL_NAME: 'Enw llawn',
  YOUR_PAYMENT_DETAILS: 'Eich manylion talu',
  PYAMENT_METHOD: 'Dull o dalu',
  CHANCES_PER_DRAW: 'Cyfleoedd fesul loteri',
  REGULAR_PAYMENT: 'Taliad rheolaidd',
  FIRST_PAYMENT_DATE: 'Dyddiad y taliad cyntaf',
  SUBMIT: 'Cyflwyno',
  RESIDENT_OF_GREAT_BRITAIN: 'Rwyf yn preswylio ym Mhrydain Fawr (Cymru, Lloegr, yr Alban)',
  RECURRING_PAYMENT_INFORMATION:
    'Pam 34c? Dros flwyddyn, maent yn cynnwys 52 loteri. Hebddo, byddech yn colli’r 5ed loteri mewn mis o 5 wythnos.',
  INITIAL_PAYMENT_INFORMATION:
    'Bydd eich taliad cyntaf £2 yn uwch fesul llinell na thaliadau rheolaidd er mwyn osgoi colli loteri mewn mis o 5 wythnos.',
  SOMETHING_WENT_WRONG_WHILE_CREATING_PAYMENT: "Aeth rhywbeth o'i le wrth greu taliad",
  YOUR_FORM_IS_SUBMITTED_SUCCESSFULLY: "Mae eich ffurflen wedi ei chyflwyno'n llwyddiannus!",
  SOMETHING_WENT_WRONG_WHILE_SUBMITTING_FORM: "Aeth rhywbeth o'i le wrth gyflwyno ffurflen",
  PLEASE_FILL_REQUIRED_DATA: 'Llenwch y data angenrheidiol',
  PLEASE_READ_ACCEPT_TERMS_AND_CONDITIONS_TO_CONTINUE: "Darllenwch a derbyniwch y telerau a'r amodau i barhau.",
  ERROR: 'Gwall',
  SUCCESS: 'Llwyddiant',
  JANUARY: 'Ionawr',
  FEBRUARY: 'Chwefror',
  MARCH: 'Mawrth',
  APRIL: 'Ebrill',
  MAY: 'Mai',
  JUNE: 'Mehefin',
  JULY: 'Gorffennaf',
  AUGUST: 'Awst',
  SEPTEMBER: 'Medi',
  OCTOBER: 'Hydref',
  NOVEMBER: 'Tachwedd',
  DECEMBER: 'Rhagfyr',
  PAYMENT_DATE_FORMAT: '{{day}} {{month}}, {{year}}',
  VENDOR_REVIEW_LINE:
    "Os yw popeth yn edrych yn dda, cliciwch Cyflwyno i ymuno â'r loteri. Am unrhyw ymholiadau, cysylltwch â {{vendor_email}} neu ffoniwch {{vendor_phone}}.",
  VENDOR_CONFIRMATION_LINE:
    "Bydd cadarnhad yn cael ei anfon atoch o fewn 3 diwrnod gwaith neu'n cael ei dderbyn gennych ddim hwyrach na 3 diwrnod gwaith cyn y casgliad cyntaf. Yr enw a fydd yn ymddangos ar eich datganiad yw “{{confirmation_name}}”.",
  'Keep Wales Tidy': 'Loteri Cymru Hardd',
};
