import styled from 'styled-components';
import backgroundClouds from '../../assets/images/clouds-background.svg';

export const MainWrapper = styled.div`
  min-height: 100dvh;
  max-height: 100dvh;
  display: flex;
  flex-direction: row;
  background: #ffffff;
  overflow: hidden;

  .main {
    display: flex;
    flex: 1;
    background-color: #ffffff;

    .loader {
      height: 100dvh;
      width: 100dvw;
    }

    .background-image {
      height: calc(100% - 200px);
      bottom: 0;
      z-index: 0;
      background-image: url(${backgroundClouds});
      background-repeat: no-repeat;
      background-size: cover;
    }

    .top-border-bar {
      height: 16px;
      background: ${({ theme }) => theme.colors.field_accent_color_text};
    }

    .language-button {
      position: absolute;
      right: 16px;
      top: 24px;
      z-index: 2;
      border-radius: 4px;
      background-color: white;

      label {
        user-select: none;
      }
    }

    .content {
      z-index: 1;
    }

    .fade-appear {
      opacity: 0;
      transform: translate(0, 15px);
    }

    .fade-appear.fade-appear-active {
      opacity: 1;
      transform: unset;
      transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
    }

    .fade-enter {
      opacity: 0;
      transform: translate(0, 15px);
    }

    .fade-enter.fade-enter-active {
      opacity: 1;
      transform: unset;
      transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
    }

    .fade-exit {
      opacity: 1;
      transform: unset;
    }

    .fade-exit.fade-exit-active {
      opacity: 0;
      transform: translate(0, 15px);
      transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
    }

    .fade-exit-done {
      opacity: 0;
      transform: unset;
    }
  }
`;
