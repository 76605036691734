import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import defaultFaviconIcon from '../../assets/images/favicon.ico';
import AddressInfo from '../../components/address-info';
import AgeVerification from '../../components/age-verification';
import ContactInfo from '../../components/contact-info';
import DebitRegistration from '../../components/debit-registration';
import Button from '../../components/elements/button/button';
import Header from '../../components/elements/header';
import Loader from '../../components/elements/loader';
import FacebookPixel from '../../components/facebook-pixel';
import Footer from '../../components/footer';
import PaymentInfo from '../../components/payment-info';
import PreferanceInfo from '../../components/preferance-info';
import RegistrationComplete from '../../components/registration-complete';
import { emailRegEx, hardCodedComponentLexicons } from '../../constant/optionData';
import LanguageContext from '../../context/LanguageContext';
import ThemePreferenceContext from '../../context/ThemePreferenceContext';
import { OrganisationContext } from '../../context/organisationContext';
import { getErrorDescription, getPaymentMethodErrorDescription, uppercase } from '../../helpers/utils';
import {
  createContact,
  createFormSession,
  createPaymentMethod,
  createPaymentMethodSchedules,
  getFormDetails,
  getFormPanelWidgets,
  getFormPanels,
  submitFormSessionData,
  updateFormSession,
  verifyBankDetails,
} from '../../store/features/formSlice';
import { addToast } from '../../store/features/toastSlice';
import { MainWrapper } from '../../styles/pages/main.styled';

const getFaviconEl = () => {
  return document.getElementById('favicon');
};

const requiredLexiconForData = [
  'vendor.review_info',
  'vendor.confirmation',
  'vendor.phone',
  'vendor.email',
  'vendor.direct_debit_label',
  'vendor.service_user_number',
  'contact.language',
  'vendor.bank_statement',
  'vendor.facebook_pixel',
];

const requiredComponentTypesForData = ['PAYMENT'];

const supportedForms = ['BloomSpeakOT'];

const Main = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const { onLanguageChange, language } = useContext(LanguageContext);
  const { value: langValue, code: langCode } = language || {};

  const { form_id } = useParams();

  const contentWrapperRef = useRef(null);
  const contactInfoWrapperRef = useRef(null);

  const { themeMapState, setThemeMapState, initialThemeMapState } = useContext(ThemePreferenceContext);

  const [formDetails, setFormDetails] = useState({});
  const [formAttributes, setFormAttributes] = useState({});
  const [formPanels, setFormPanels] = useState([]);
  const [formWidgets, setFormWidgets] = useState([]);
  const [formComponentsForRequiredData, setFormComponentsForRequiredData] = useState({});
  const [formComponentsByComponentTypes, setFormComponentsByComponentTypes] = useState({});
  const [isFormDataLoading, setIsFormDataLoading] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const [formSession, setFormSession] = useState({});

  const [age, setAge] = useState({});
  const [contact, setContact] = useState({});
  const [address, setAddress] = useState({});
  const [preferance, setPreferance] = useState({});
  const [payment, setPayment] = useState({ entries: 1, direct_debit: true });
  const [debitRegistration, setDebitRegistration] = useState({});

  const [ageVerified, setAgeVerified] = useState(false);
  const [showDebitRegistration, setShowDebitRegistration] = useState(false);
  const [showRegistrationComplete, setShowRegistrationComplete] = useState(false);

  const [contactError, setContactError] = useState({});
  const [addressError, setAddressError] = useState({});
  const [paymentError, setPaymentError] = useState({});
  const [debitRegistrationError, setDebitRegistrationError] = useState({});

  useEffect(() => {
    const lang = searchParams.get('lang');
    if (lang) {
      onLanguageChange(lang);
    }
  }, [searchParams]);

  const resetStates = () => {
    setIsSubmittingForm(false);
    setAge({});
    setAgeVerified(false);
    setShowDebitRegistration(false);
    setShowRegistrationComplete(false);
    setContact({});
    setAddress({});
    setPreferance({});
    setPayment({ entries: 1, direct_debit: true });
    setDebitRegistration({});
    setContactError({});
    setAddressError({});
    setPaymentError({});
    setDebitRegistrationError({});
  };

  const createFormSessionRequest = () => {
    const { id, version, api_key } = formDetails || {};
    const formSessionRequest = {
      session_start: moment().unix(),
      session_end: '',
      user: null,
      form_version: {
        id: version?.id,
      },
      form: {
        id: id,
      },
      form_type: '',
    };
    dispatch(createFormSession({ request: formSessionRequest, api_key: api_key }))
      .then(data => setFormSession(data))
      .catch(error => {
        const errorText = getErrorDescription(error, 'Something went wrong while creating form session');
        dispatch(addToast({ error: true, text: errorText, id: 'form-session' }));
      });
  };

  const endFormSessionRequest = async () => {
    const formEndRequest = {
      session_end: moment().unix(),
      user: null,
    };
    await dispatch(updateFormSession({ id: formSession.id, request: formEndRequest, api_key: formDetails?.api_key }));
  };

  useEffect(() => {
    try {
      navigator?.serviceWorker?.getRegistrations()?.then(regs => regs && regs?.forEach(reg => reg && reg?.update()));
    } catch (error) {
      console.log('error in service worker', error);
    }
  }, [location]);

  useEffect(() => {
    if (ageVerified && formDetails?.id) {
      createFormSessionRequest();
    }
  }, [ageVerified]);

  const checkContactError = () => {
    const { title, forename, surname, email, mobile, home } = contact;
    const error = {
      title: !title,
      forename: !forename?.trim(),
      surname: !surname?.trim(),
      email: !email?.trim(),
      mobile: !mobile?.trim() && !home?.trim(),
      home: !mobile?.trim() && !home?.trim(),
      mobileInvalid: mobile ? mobile.replaceAll('+44', '').replaceAll(' ', '').length !== 10 : false,
      homeInvalid: home ? home.replaceAll('+44', '').replaceAll(' ', '').length !== 11 : false,
      emailInvalid: email ? !emailRegEx.test(email) : false,
    };
    setContactError(error);
    return Object.values(error).some(err => Boolean(err));
  };

  const checkAddressError = () => {
    const { formatted_address, line1, city, state, postcode } = address;
    const error = {
      formatted_address: !formatted_address?.trim(),
      line1: !line1?.trim(),
      city: !city?.trim(),
      postcode: !postcode?.trim(),
    };
    setAddressError(error);
    return Object.values(error).some(err => Boolean(err));
  };

  const checkPaymentError = () => {
    const { terms_conditions } = payment;
    const error = {
      terms_conditions: !terms_conditions,
    };
    setPaymentError(error);
    return Object.values(error).some(err => Boolean(err));
  };

  const onContinuePayment = () => {
    const isContactError = checkContactError();
    const isAddressError = checkAddressError();
    const isPaymentError = checkPaymentError();
    if (isContactError || isAddressError) {
      dispatch(addToast({ error: true, text: t('PLEASE_FILL_REQUIRED_DATA'), id: 'required-data' }));
      contactInfoWrapperRef.current.scrollIntoView({
        behavior: 'smooth',
      });
      return;
    }
    if (isPaymentError) {
      dispatch(
        addToast({
          error: true,
          text: t('PLEASE_READ_ACCEPT_TERMS_AND_CONDITIONS_TO_CONTINUE'),
          id: 'terms-condition-accept',
        }),
      );
      return;
    }
    setShowDebitRegistration(true);
    contentWrapperRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const checkDebitRegistrationError = () => {
    const { date, accountHolderName, sortCode, accountNumber, authorizedDebitCheck, validAgeCheck, residentOfBritain } =
      debitRegistration;

    const error = {
      date: !date,
      accountHolderName: !accountHolderName?.trim(),
      sortCode: !sortCode?.trim() || (sortCode && sortCode.length !== 8),
      accountNumber: !accountNumber?.trim() || (accountNumber && accountNumber.length !== 8),
      authorizedDebitCheck: !authorizedDebitCheck,
      validAgeCheck: !validAgeCheck,
      residentOfBritain: !residentOfBritain,
    };
    setDebitRegistrationError(error);
    return Object.values(error).some(err => Boolean(err));
  };

  const onReviewForm = async () => {
    const isDebitRegistrationError = checkDebitRegistrationError();
    if (isDebitRegistrationError) {
      dispatch(addToast({ error: true, text: t('PLEASE_FILL_REQUIRED_DATA'), id: 'required-data' }));
      contentWrapperRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }
    try {
      setIsSubmittingForm(true);
      const paymentComponent = getComponentByComponentType('PAYMENT') || {};
      const { integration } = paymentComponent || {};

      const { api_key } = formDetails;
      const { sortCode, accountNumber } = debitRegistration;
      const bankVerify = await dispatch(
        verifyBankDetails({
          request: {
            account_number: accountNumber,
            sort_code: sortCode.replaceAll('-', ''),
            integration: { id: integration?.id },
          },
          api_key,
        }),
      );
      if (!bankVerify?.valid) {
        const errorText = bankVerify?.error || 'Invalid bank details';
        dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
        setIsSubmittingForm(false);
        return;
      }
    } catch (error) {
      dispatch(addToast({ error: true, text: 'Something went wrong while verifying bank details', id: nanoid() }));
      setIsSubmittingForm(false);
      return;
    }
    setIsSubmittingForm(false);
    setShowRegistrationComplete(true);
    setShowDebitRegistration(false);
    contentWrapperRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const createMarketingPreferencesRequest = () => {
    const vendorId = formDetails?.vendor?.id;
    const { email, sms, phoneCall, zenEmail, zenSms, zenPost } = preferance;
    return [
      { send_by: 'EMAIL', consent: !!email, organization: { id: vendorId } },
      { send_by: 'SMS', consent: !!sms, organization: { id: vendorId } },
      { send_by: 'PHONE', consent: !!phoneCall, organization: { id: vendorId } },
      { send_by: 'EMAIL', consent: !!zenEmail, organization: { id: 'cde2f315-c316-11ed-8066-4200fe9a0006' } },
      { send_by: 'SMS', consent: !!zenSms, organization: { id: 'cde2f315-c316-11ed-8066-4200fe9a0006' } },
      { send_by: 'PHONE', consent: !!zenPost, organization: { id: 'cde2f315-c316-11ed-8066-4200fe9a0006' } },
    ];
  };

  const createContactRequest = () => {
    const { title, forename, surname, email, home, mobile } = contact;
    const { day, month, year } = age;

    const phoneRequest = {
      contact_type: 'MOBILE',
      contact_value: '',
      is_primary: true,
      phone_type: 'WORK',
      country_code: '+44',
    };

    const dobMoment = moment(`${day}/${month}/${year}`, 'DD/MM/YYYY');

    return {
      title: title,
      forename: forename,
      surname: surname,
      date_of_birth: dobMoment.unix(),
      language: uppercase(langCode),
      emails: [
        {
          value: email,
          is_primary: true,
          email_type: 'WORK',
        },
      ],
      phones: [
        ...(home
          ? [
              {
                ...phoneRequest,
                contact_value: home.replaceAll('+44', '').replaceAll(' ', ''),
                contact_type: 'LANDLINE',
              },
            ]
          : []),
        ...(mobile
          ? [
              {
                ...phoneRequest,
                contact_value: mobile.replaceAll('+44', '').replaceAll(' ', ''),
                contact_type: 'MOBILE',
              },
            ]
          : []),
      ],
      addresses: [
        {
          country_code: 'GB',
          ...address,
          is_primary: true,
          is_postal: true,
          is_billing: true,
          is_residential: true,
        },
      ],
      marketing_preferences: createMarketingPreferencesRequest(),
      call_time: null,
      integration: null,
      profession: null,
    };
  };

  const createPaymentMethodRequest = () => {
    const { sortCode, accountNumber, accountHolderName } = debitRegistration;
    const paymentComponent = getComponentByComponentType('PAYMENT') || {};
    const { integration } = paymentComponent || {};

    return {
      external_reference: null,
      integration: { id: integration?.id },
      bank: {
        sort_code: sortCode.replaceAll('-', ''),
        account_number: accountNumber,
        account_holder: accountHolderName,
      },
      wallet: null,
      idempotency_key: formSession?.id,
    };
  };

  const createPaymentMethodScheduleRequest = () => {
    const { entries } = payment;
    const { date } = debitRegistration;
    return {
      first_payment: {
        amount: parseFloat(6.34 * entries).toFixed(2),
        date: date,
      },
      following_payments: {
        amount: parseFloat(4.34 * entries).toFixed(2),
        interval: 'MONTH',
        interval_count: 1,
      },
    };
  };

  const getFormComponentsForSubmission = () => {
    return formWidgets?.reduce((acc, widget) => {
      const { components } = widget || {};
      if (!components?.length) return acc;
      const widgetComponents = components.filter(c => hardCodedComponentLexicons.includes(c.lexicon));
      acc = [...acc, ...widgetComponents];
      return acc;
    }, []);
  };

  const createFormSubmissionRequest = values => {
    const formComponents = getFormComponentsForSubmission();
    const formSubmissionRequest = formComponents
      .map(component => {
        const { lexicon, id } = component || {};
        const value = values[lexicon];
        return {
          panel_widget_component: {
            id: id,
          },
          submitted_data: value,
          lexicon: component.lexicon,
        };
      })
      .filter(item => item.submitted_data);
    return formSubmissionRequest;
  };

  const onSubmitForm = async () => {
    const { api_key } = formDetails;
    try {
      setIsSubmittingForm(true);
      const contactRequest = createContactRequest();
      const paymentMethodRequest = createPaymentMethodRequest();
      const paymentMethodScheduleRequest = createPaymentMethodScheduleRequest();
      const { data: contactData } = await dispatch(createContact({ request: contactRequest, api_key }));
      let paymentMethod = {};
      try {
        const { data } = await dispatch(
          createPaymentMethod({ contactId: contactData.id, request: paymentMethodRequest, api_key }),
        );
        paymentMethod = data;
      } catch (error) {
        const errorText = getPaymentMethodErrorDescription(error, t('SOMETHING_WENT_WRONG_WHILE_CREATING_PAYMENT'));
        dispatch(addToast({ error: true, text: errorText, id: 'payment-method' }));
        setIsSubmittingForm(false);
        return;
      }
      await dispatch(
        createPaymentMethodSchedules({
          paymentMethodId: paymentMethod.id,
          request: paymentMethodScheduleRequest,
          api_key,
        }),
      );
      if (formSession?.id) {
        const lexiconValues = hardCodedComponentLexicons.reduce((acc, lexicon) => {
          if (lexicon === 'contact.entries') {
            acc[lexicon] = payment.entries;
          }
          if (lexicon === 'contact.payment_frequency') {
            acc[lexicon] = 'MONTH';
          }
          if (lexicon === 'contact.payment_date') {
            acc[lexicon] = debitRegistration.date;
          }
          if (lexicon === 'contact.payment_day') {
            acc[lexicon] = moment.unix(debitRegistration.date).get('date');
          }
          if (lexicon === 'contact.initial_amount') {
            acc[lexicon] = parseFloat(6.34 * payment.entries).toFixed(2);
          }
          if (lexicon === 'contact.payment_amount') {
            acc[lexicon] = parseFloat(4.34 * payment.entries).toFixed(2);
          }
          if (lexicon === 'contact.payment_method.id') {
            acc[lexicon] = paymentMethod.id;
          }
          if (lexicon === 'contact.id') {
            acc[lexicon] = contactData.id;
          }
          if (lexicon === 'contact.title') {
            acc[lexicon] = contact.title;
          }
          if (lexicon === 'contact.forename') {
            acc[lexicon] = contact.forename;
          }
          if (lexicon === 'contact.surname') {
            acc[lexicon] = contact.surname;
          }
          if (lexicon === 'contact.email') {
            acc[lexicon] = contact.email;
          }
          if (lexicon === 'contact.mobile') {
            acc[lexicon] = contact.mobile?.replaceAll('+44', '').replaceAll(' ', '');
          }
          if (lexicon === 'contact.landline') {
            acc[lexicon] = contact.home?.replaceAll('+44', '').replaceAll(' ', '');
          }
          if (lexicon === 'contact.address.line1') {
            acc[lexicon] = address.line1;
          }
          if (lexicon === 'contact.address.line2') {
            acc[lexicon] = address.line2;
          }
          if (lexicon === 'contact.address.line3') {
            acc[lexicon] = address.line3;
          }
          if (lexicon === 'contact.address.city') {
            acc[lexicon] = address.city;
          }
          if (lexicon === 'contact.address.state') {
            acc[lexicon] = address.state;
          }
          if (lexicon === 'contact.address.postcode') {
            acc[lexicon] = address.postcode;
          }
          if (lexicon === 'contact.language') {
            acc[lexicon] = langCode;
          }
          return acc;
        }, {});
        const formSubmissionRequest = createFormSubmissionRequest(lexiconValues);
        await dispatch(submitFormSessionData({ id: formSession.id, request: formSubmissionRequest, api_key }));
        await endFormSessionRequest();
      }
      setIsSubmittingForm(false);
      dispatch(addToast({ error: false, text: t('YOUR_FORM_IS_SUBMITTED_SUCCESSFULLY'), id: 'form-submit-success' }));
      const isLanguageOptionAvailable = getComponentByLexicon('contact.language');

      let webUrl = formAttributes?.redirect_url
        ? formAttributes?.redirect_url
        : `https://zenlottery.com/play/thank-you/?charity=${formDetails?.vendor?.name}`;
      if (!!webUrl && !/^https?:\/\//i.test(webUrl)) {
        webUrl = `https://${webUrl}`;
      }
      if (isLanguageOptionAvailable) {
        const webUrlContainsParams = webUrl.includes('?');
        webUrl = `${webUrl}${webUrlContainsParams ? '&' : '?'}language=${langCode}`;
      }
      window.location.replace(webUrl);
    } catch (error) {
      dispatch(
        addToast({ error: true, text: t('SOMETHING_WENT_WRONG_WHILE_SUBMITTING_FORM'), id: 'form-submit-error' }),
      );
      setIsSubmittingForm(false);
    }
  };

  const updateTheme = formAttribute => {
    if (!formAttribute) {
      setThemeMapState(initialThemeMapState);
      return;
    }
    const { field_accent_color, color, font } = formAttribute;
    const { name } = font || {};
    const isFontSupported = supportedForms.includes(name);
    const updatedThemeMapState = Object.entries(themeMapState).reduce((acc, [key, value]) => {
      acc[key] = {
        ...value,
        font: isFontSupported ? name : null,
        colors: {
          ...value.colors,
          field_accent_color: field_accent_color || value.colors.field_accent_color,
          field_accent_color_text: field_accent_color || value.colors.field_accent_color_text,
          checkbox_checked: field_accent_color || value.colors.checkbox_checked,
          button_color: color?.button || value.colors.button_color,
        },
      };
      return acc;
    }, {});
    setThemeMapState(updatedThemeMapState);
  };

  const updateFavicon = (formAttribute, vendor_name) => {
    const faviconEl = getFaviconEl();
    const { favicon } = formAttribute || {};
    if (!faviconEl) return;
    if (!favicon || !favicon.url) {
      faviconEl.href = defaultFaviconIcon;
    } else {
      faviconEl.href = favicon.url;
    }
    if (vendor_name) {
      document.title = vendor_name;
    }
  };

  const getComponentByLexicons = (formWidgets, lexicons) => {
    return formWidgets
      .map(widget => widget.components)
      .flat()
      .reduce((acc, component) => {
        const { lexicon } = component;
        if (lexicon && lexicons.includes(lexicon)) {
          acc[lexicon] = component;
        }
        return acc;
      }, {});
  };

  const getComponentByComponentTypes = (formWidgets, componentTypes) => {
    return formWidgets
      .map(widget => widget.components)
      .flat()
      .reduce((acc, component) => {
        const { component_type } = component;
        if (component_type && componentTypes.includes(component_type)) {
          acc[component_type] = component;
        }
        return acc;
      }, {});
  };

  const fetchFormData = async form_id => {
    try {
      setIsFormDataLoading(true);
      let formWidgets = [];
      const lang = searchParams.get('lang');
      const formDetails = await dispatch(getFormDetails({ form_id }));
      const formPanels = await dispatch(getFormPanels({ form_id, api_key: formDetails?.api_key }));

      if (formPanels?.length) {
        formWidgets = await dispatch(
          getFormPanelWidgets({
            panel_id: formPanels[0]?.id,
            api_key: formDetails?.api_key,
            params: {
              language: lang || 'en',
            },
          }),
        );
      }

      const formAttribute = formDetails?.attributes?.[0] || null;
      updateTheme(formAttribute);
      updateFavicon(formAttribute, formDetails?.vendor?.name);
      setFormDetails(formDetails);
      setFormAttributes(formAttribute);
      setFormPanels(formPanels);
      setFormWidgets(formWidgets);
      setFormComponentsForRequiredData(getComponentByLexicons(formWidgets, requiredLexiconForData));
      setFormComponentsByComponentTypes(getComponentByComponentTypes(formWidgets, requiredComponentTypesForData));
      setIsFormDataLoading(false);
    } catch (error) {
      setFormDetails({});
      setFormAttributes({});
      setFormPanels([]);
      setFormWidgets([]);
      setIsFormDataLoading(false);
    }
  };

  const refetchFormWidgetsOnLanguageChange = async newLanguage => {
    try {
      let formWidgets = [];

      const firstPanel = formPanels?.[0];
      if (firstPanel) {
        formWidgets = await dispatch(
          getFormPanelWidgets({
            panel_id: firstPanel.id,
            api_key: formDetails?.api_key,
            params: {
              language: newLanguage,
            },
          }),
        );
      }
      setFormWidgets(formWidgets);
      setFormComponentsForRequiredData(getComponentByLexicons(formWidgets, requiredLexiconForData));
      setFormComponentsByComponentTypes(getComponentByComponentTypes(formWidgets, requiredComponentTypesForData));
    } catch (error) {
      console.log('error in refetchFormWidgetsOnLanguageChange', error);
    }
  };

  useEffect(() => {
    resetStates();
    fetchFormData(form_id);
  }, [form_id]);

  const getComponentByLexicon = lexicon => {
    return formComponentsForRequiredData?.[lexicon] || null;
  };

  const getComponentByComponentType = componentType => {
    return formComponentsByComponentTypes?.[componentType] || null;
  };

  const onChangeLanguage = () => {
    const newLanguage = langValue === 'ENGLISH' ? 'cy' : 'en';
    onLanguageChange(newLanguage);
    setSearchParams({ lang: newLanguage }, { replace: true });
    refetchFormWidgetsOnLanguageChange(newLanguage);
  };

  return (
    <MainWrapper>
      <OrganisationContext.Provider
        value={{
          formDetails: formDetails,
          vendor: formDetails?.vendor,
          api_key: formDetails?.api_key,
          formWidgets: formWidgets,
          getComponentByLexicon: getComponentByLexicon,
          getComponentByComponentType: getComponentByComponentType,
        }}>
        <div className="flex-column items-center main relative">
          {isFormDataLoading ? (
            <Loader />
          ) : (
            <Fragment>
              <FacebookPixel pixelId={getComponentByLexicon('vendor.facebook_pixel')?.name} />
              <div className="w-full top-border-bar" />
              <CSSTransition
                in={!isFormDataLoading}
                appear={!isFormDataLoading}
                timeout={{
                  appear: 500,
                  enter: 500,
                  exit: 500,
                }}
                classNames="fade"
                unmountOnExit>
                <div
                  className="flex-column flex-1 w-full items-center content overflow-scroll relative"
                  ref={contentWrapperRef}>
                  {getComponentByLexicon('contact.language') && (
                    <Button
                      className="language-button custom-button-secondary specified-width hover-button"
                      width="120px"
                      label={uppercase(langValue === 'ENGLISH' ? t('WELSH') : t('ENGLISH'))}
                      onClick={() => onChangeLanguage()}
                    />
                  )}
                  <Header langValue={langValue} onChangeLanguage={onChangeLanguage} />
                  <form className="flex-column main-background-color w-full flex-1">
                    <CSSTransition
                      in={!showDebitRegistration && !showRegistrationComplete}
                      appear={!showDebitRegistration && !showRegistrationComplete}
                      timeout={{
                        appear: 500,
                        enter: 300,
                        exit: 500,
                      }}
                      classNames="fade"
                      unmountOnExit>
                      <Fragment>
                        <div className="flex-column items-center justify-center w-full pb-2 mb-5">
                          <AgeVerification age={age} setAge={setAge} setAgeVerified={setAgeVerified} />
                        </div>
                      </Fragment>
                    </CSSTransition>
                    <CSSTransition
                      in={ageVerified && !showDebitRegistration && !showRegistrationComplete}
                      appear={ageVerified && !showDebitRegistration && !showRegistrationComplete}
                      timeout={{
                        appear: 500,
                        enter: 300,
                        exit: 500,
                      }}
                      classNames="fade"
                      unmountOnExit
                      onEnter={() => {
                        contactInfoWrapperRef.current.scrollIntoView({
                          behavior: 'smooth',
                        });
                      }}>
                      <div className="flex-column">
                        <div
                          className="flex-column items-center justify-center white-background w-full pb-2 mb-5"
                          ref={contactInfoWrapperRef}>
                          <ContactInfo contact={contact} setContact={setContact} contactError={contactError} />
                          <AddressInfo address={address} setAddress={setAddress} addressError={addressError} />
                        </div>
                        <div className="flex-column items-center justify-center w-full pb-2 mb-5">
                          <PreferanceInfo preferance={preferance} setPreferance={setPreferance} />
                        </div>
                        <div className="flex-column items-center justify-center white-background w-full pb-2">
                          <PaymentInfo
                            payment={payment}
                            setPayment={setPayment}
                            setShowDebitRegistration={setShowDebitRegistration}
                            onSubmit={() => onContinuePayment()}
                            paymentError={paymentError}
                          />
                        </div>
                      </div>
                    </CSSTransition>
                    <CSSTransition
                      in={showDebitRegistration}
                      appear={showDebitRegistration}
                      timeout={{
                        appear: 500,
                        enter: 300,
                        exit: 500,
                      }}
                      classNames="fade"
                      unmountOnExit>
                      <div className="flex-column items-center justify-center w-full pb-2">
                        <DebitRegistration
                          contact={contact}
                          debitRegistration={debitRegistration}
                          setDebitRegistration={setDebitRegistration}
                          onBack={() => {
                            setShowDebitRegistration(false);
                            contentWrapperRef.current.scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                          isSubmittingForm={isSubmittingForm}
                          debitRegistrationError={debitRegistrationError}
                          onSubmit={() => onReviewForm()}
                        />
                      </div>
                    </CSSTransition>
                    <CSSTransition
                      in={showRegistrationComplete}
                      appear={showRegistrationComplete}
                      timeout={{
                        appear: 500,
                        enter: 300,
                        exit: 500,
                      }}
                      classNames="fade"
                      unmountOnExit>
                      <div className="flex-column items-center justify-center w-full pb-2">
                        <RegistrationComplete
                          contact={contact}
                          address={address}
                          payment={payment}
                          debitRegistration={debitRegistration}
                          onBack={() => {
                            setShowDebitRegistration(true);
                            setShowRegistrationComplete(false);
                            contentWrapperRef.current.scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                          onSubmit={() => onSubmitForm()}
                          isSubmittingForm={isSubmittingForm}
                        />
                      </div>
                    </CSSTransition>
                  </form>
                  <Footer footerWidget={formWidgets.find(w => w.widget_type === 'FOOTER')} />
                </div>
              </CSSTransition>
            </Fragment>
          )}
        </div>
      </OrganisationContext.Provider>
    </MainWrapper>
  );
};

export default Main;
