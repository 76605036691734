import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { dayOptions, monthOptions, yearOptions } from '../../constant/optionData';
import Box from '../elements/box';
import Dropdown from '../elements/dropdown/index';

const AgeVerification = ({ age, setAge, setAgeVerified }) => {
  const { t } = useTranslation();

  const [error, setError] = useState(null);

  const translatedMonthOptions = useMemo(() => {
    return monthOptions.map(option => ({ ...option, label: t(option.key) }));
  }, [t]);

  useEffect(() => {
    const { day, month, year } = age;
    if (day && month && year) {
      const date = `${day}/${month}/${year}`;
      const dobMoment = moment(date, 'DD/MM/YYYY');
      if (!dobMoment.isValid()) {
        setAgeVerified(false);
        setError(t('PLEASE_ENTER_VALID_DATE_OF_BIRTH'));
        return;
      }
      const yearDiff = moment().diff(dobMoment, 'years', false);
      if (yearDiff < 18) {
        setAgeVerified(false);
        setError(t('MUST_BE_18_OR_OVER'));
        return;
      }
      setAgeVerified(true);
      setError(null);
    }
  }, [age]);

  return (
    <AgeVerificationWrapper>
      <Box title={t('AGE_VERIFICATION')}>
        <label className="roboto-regular-text font-20 mt-6 text-center">{t('PLEASE_ENTER_DATE_OF_BIRTH')}</label>
        <label className="roboto-regular-text main-grey-text mt-2 text-center">{t('MUST_BE_ABOVE_18')}</label>
        <div className="flex w-full mt-8 col-gap-6 age-content-dropdown">
          <Dropdown
            is_required
            name={t('DAY')}
            options={dayOptions}
            placeholder={t('DAY')}
            selectedValue={dayOptions.find(option => option.value === age.day)}
            onChange={option => setAge({ ...age, day: option.value })}
            error={error}
          />
          <Dropdown
            is_required
            name={t('MONTH')}
            options={translatedMonthOptions}
            placeholder={t('MONTH')}
            selectedValue={translatedMonthOptions.find(option => option.value === age.month)}
            onChange={option => setAge({ ...age, month: option.value })}
            error={error}
          />
          <Dropdown
            is_required
            name={t('YEAR')}
            options={yearOptions}
            placeholder={t('YEAR')}
            selectedValue={yearOptions.find(option => option.value === age.year)}
            onChange={option => setAge({ ...age, year: option.value })}
            error={error}
          />
        </div>
        {error && <label className="roboto-regular-text error-text text-center mt-2 w-full">{error}</label>}
      </Box>
    </AgeVerificationWrapper>
  );
};

export const AgeVerificationWrapper = styled.div`
  @media (max-width: 961px) {
    .age-content-dropdown {
      flex-direction: column;
      row-gap: 16px;
    }
  }
`;

export default AgeVerification;
